import { SET_IS_LOADING, SET_USER, SET_USER_DATA } from "../types/auth"

export const setUser = (user) => (
    {
        type: SET_USER,
        payload: {
            user
        }
    }
)

export const setUserData = (userData) => (
    {
        type: SET_USER_DATA,
        payload: {
            userData
        }
    }
)

export const setIsLoading = (isLoading) => (
    {
        type: SET_IS_LOADING,
        payload: {
            isLoading
        }
    }
)
