import { toast } from "react-toastify";

const notifyToast = (isSuccess, msg) => {
  const attributes = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  if (isSuccess) toast.success(msg, attributes);
  else toast.error(msg, attributes);
  return <></>;
};

export default notifyToast;
