import Grid from "@mui/material/Unstable_Grid2";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import GobackBtn from "../../components/GobackBtn/GobackBtn";
import Loading from "../../components/Loading/Loading";
import { db, storage } from "../../lib/firebase";
import { checkAuthorization } from "../../lib/sectionAuthorization";
import "./HandbookCreator.styles.css";

function HandbookCard(props) {
  const navigate = useNavigate();

  function handleOnViewClick() {
    console.log("handbook:", props);
    navigate("/handbookCreator/handbook", {
      state: {
        name: props.name,
        imgurl: props.imgurl,

        desc: props.desc,

        id: props.id,
        imageId: props.imageId,
      },
    });
  }
  return (
    <div className="creator-card">
      <img
        alt=""
        src={props.imgurl}
        width="220px"
        className="creator-card-img"
        onClick={handleOnViewClick}
      />
      <div className="creator-card-title-div">{props.name}</div>
      {/* <div className="creator-card-subtitle-div">
        <div style={{ fontSize: 12 }}>{props.desc}</div>
        <button className="creator-card-view-btn" onClick={handleOnViewClick}>
          View
        </button>
      </div> */}
    </div>
  );
}

function HandbookCreator() {
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(true);
  const [createbtn, setCreatebtn] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [color1, setColor1] = useState("rgb(61, 90, 254)");
  const [color2, setColor2] = useState("#F1F1F1");
  const [color3, setColor3] = useState("#F1F1F1");
  const [handbookArray, setArray] = useState([]);
  const [nameI, setName] = useState("");
  const [descI, setDesc] = useState("");

  const [image, setImage] = useState("");
  const [error, setError] = useState("");
  const [isActiveNext, setIsActiveNext] = useState(true);

  const userData = useSelector((state) => state.auth.userData);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!userData) return;
    if (checkAuthorization(userData, "handbook")) {
      setIsLoading(false);
      getGroups();
    } else {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (image) setCreatebtn(false);
    else setCreatebtn(true);
  }, [image]);

  //Firebase function to addGroup data
  async function addGroups(randomName, imageId, imageUrl) {
    try {
      console.log("ImageId :" + imageId);
      await setDoc(doc(db, "handbook", randomName), {
        name: nameI,
        description: descI,
        imageUrl: imageUrl,

        imageId: imageId,
      });
      await updateDoc(doc(db, "handbook", randomName), { id: randomName });
      //console.log("Document written with ID: ", );
      setImage(null);

      getGroups();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  //Firebase function to getgroup data
  async function getGroups() {
    const querySnapshot = await getDocs(collection(db, "handbook"));
    let array = [];
    querySnapshot.forEach((doc) => {
      var customJson = {
        name: doc.data().name,

        imageUrl: doc.data().imageUrl,
        description: doc.data().description,

        imageId: doc.data().imageId,
        id: doc.data().id,
      };
      // console.log("classmates :",customJson.classmates)
      array.push(customJson);
    });
    //console.log(handbookArray)

    setArray(array);
  }

  const items = handbookArray.map((items) => (
    // <li>{items.name}</li>
    <Grid xs={2} sm={2} md={2}>
      <HandbookCard
        name={items.name}
        imgurl={items.imageUrl}
        desc={items.description}
        id={items.id}
        imageId={items.imageId}
      />
    </Grid>
  ));
  console.log("items array:", items);

  const formList = [
    {
      title: "Add Handbook Name :",
      placeholder: "Name",
    },
    {
      title: "Add Handbook Description :",
      placeholder: "Description",
    },
    {
      title: "Add Handbook icon :",
      placeholder: "Photo",
    },
  ];

  //fn to handle button click next
  function onHandleNext() {
    setCount((count + 1) % 3);
    if (count === 1) {
      console.log("count:", count);
      setShow(false);

      setColor2("rgb(61, 90, 254)");
      setColor3("rgb(61, 90, 254)");
    } else if (count === 0) {
      setShow(true);
      setColor2("rgb(61, 90, 254)");
      setColor3("#F1F1F1");
    } else {
      setShow(true);
      setColor2("#F1F1F1");
      setColor3("#F1F1F1");
    }
  }

  //function to image upload
  function firebaseImageUpload() {
    console.log("image name :", image.name);

    const randomName = uuidv4();
    const storageRef = ref(
      storage,
      `/admin/handbook/${randomName}/${image.name}`
    );

    // 'file' comes from the Blob or File API

    uploadBytes(storageRef, image).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        console.log("File available at", downloadURL);
        const imageUrl = downloadURL;
        addGroups(randomName, image.name, imageUrl);
      });
    });
  }

  //function to handle input
  function handleInput(input) {
    if (count === 0) {
      if (input.length > 1) {
        console.log("Name:", nameI);
        setIsActiveNext(false);
        setName(input);
        setDesc("");
        setError("");
      } else {
        setIsActiveNext(true);
        setError("Name can't be empty");
      }
    } else if (count === 1) {
      if (input.length > 1) {
        setDesc(input);
        setError("");
        setIsActiveNext(false);
        console.log("Desc:", descI);
      } else {
        setIsActiveNext(true);
        setError("Description can't be empty");
      }
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <GobackBtn />
      <div className="creator-main-div">
        <div className="creator-submain-div">
          <div className="creator-creation-card">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  className="creator-circle"
                  style={{ backgroundColor: color1 }}
                >
                  1
                </button>
                <div
                  className="creator-line"
                  style={{ backgroundColor: color2 }}
                ></div>
                <button
                  className="creator-circle"
                  style={{ backgroundColor: color2 }}
                >
                  2
                </button>
                <div
                  className="creator-line"
                  style={{ backgroundColor: color3 }}
                ></div>
                <button
                  className="creator-circle"
                  style={{ backgroundColor: color3 }}
                >
                  3
                </button>
              </div>
              <h2>{formList[count].title}</h2>
              <div>
                {show ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <input
                        className="creator-input"
                        placeholder={formList[count].placeholder}
                        onChange={(event) => handleInput(event.target.value)}
                      ></input>
                      <span style={{ color: "red" }}>{error}</span>
                    </div>
                    <button
                      disabled={isActiveNext}
                      onClick={onHandleNext}
                      className="creator-next-btn"
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {/* <button type='file' className='creator-upload-btn' onClick={()=>handleInput("#")}> + Upload</button> */}
                    <label className="creator-upload-btn" for="files">
                      + Upload
                    </label>

                    <input
                      id="files"
                      accept="image/*"
                      type="file"
                      style={{ visibility: "hidden" }}
                      placeholder="upload"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                    ></input>

                    <button
                      disabled={createbtn}
                      className="creator-create-btn"
                      onClick={() => {
                        firebaseImageUpload();
                        onHandleNext();
                      }}
                    >
                      Create
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <lottie-player
            src="https://assets9.lottiefiles.com/private_files/lf30_1Fy94C.json"
            background="transparent"
            speed="1"
            style={{ width: 400, height: 300 }}
            autoplay
            loop
          ></lottie-player>
        </div>
        <div
          style={{ display: "flex", height: 4, backgroundColor: "#000000" }}
        ></div>
        <h1 style={{ color: "var(--navyBlue)" }}>All Handbooks :</h1>
        <div className="creator-card-div">
          {/* <ul className="creator-card-ul">{items}</ul> */}
          <Grid container spacing={2} columns={{ xs: 2, sm: 6, md: 8 }}>
            {items}
          </Grid>
        </div>
      </div>
    </>
  );
}

export default HandbookCreator;
