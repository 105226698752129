import { Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import notifyToast from "../../components/Toast/Toast";
import { pathConstants } from "../../util/constants";
import { UpdateFacilities, getSchools } from "../../api/flightSchoolApis";
import * as Yup from "yup";

const Facilities = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();

  const initialValues = useSelector((state) => state.flightSchool.data).filter(
    (item) => item.id === id,
  )[0];

  // Validation for fields
  const validationSchema = Yup.object({
    facilities: Yup.array().of(Yup.string()).notRequired(),
  });

  //  On Save basic info.
  const onSubmit = async (values) => {
    // For update basic info
    if (id) {
      try {
        const response = await UpdateFacilities(values.facilities, type, id);
        notifyToast(true, response.message);
      } catch (error) {
        notifyToast(false, error);
      } finally {
        dispatch(
          getSchools(
            type === "flightSchool"
              ? pathConstants.flightSchoolDoc
              : pathConstants.typeRatingDoc,
          ),
        );
      }
    }
    // For save basic info
    else {
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="col-8 mb-10 p-3">
          {/* Begin: Facilities */}
          <label className="col-lg-6 col-form-label required fw-bold fs-6">
            Facilities
          </label>
          <FieldArray name="facilities">
            {(fieldArrayProps) => {
              const { form, push, remove, insert } = fieldArrayProps;
              const { values } = form;
              const { facilities } = values;
              return (
                <div>
                  {facilities?.length > 0 ? (
                    facilities.map((item, index) => (
                      <div key={index} className="row">
                        <Field
                          name={`facilities[${index}]`}
                          type="text"
                          className="form-control form-control-lg form-control-solid col-3 m-1"
                          placeholder="Facility"
                        />

                        <button
                          className="gradient-bg-danger m-1 h-12 w-12 rounded-md p-1"
                          onClick={() => remove(index)}
                          type="button"
                        >
                          -
                        </button>
                        <button
                          className="gradient-bg-success m-1 h-12 w-12 rounded-md p-1"
                          onClick={() => insert(index + 1, "")}
                          type="button"
                        >
                          +
                        </button>
                      </div>
                    ))
                  ) : (
                    <button
                      className="gradient-bg-success m-1 h-12 w-12 rounded-md p-1"
                      onClick={() => push("")}
                      type="button"
                    >
                      +
                    </button>
                  )}
                </div>
              );
            }}
          </FieldArray>
          {/* End: Facilities */}
        </div>
        <button
          className="gradient-bg fixed bottom-20 right-20 rounded-md p-2 text-white"
          type="submit"
        >
          Save
        </button>
      </Form>
    </Formik>
  );
};

export default Facilities;
