import React from "react";
import { useNavigate } from "react-router-dom";

import "./SectionCard.styles.css";

function SectionCard({ title, subTitle, icon, route }) {
  const navigate = useNavigate();

  const handleOnCardClick = () => navigate(route);

  return (
    <div className="sec-card" onClick={handleOnCardClick}>
      <div className="section-text">
        <div className="section-title">{title}</div>
        <div className="section-sub-title">{subTitle}</div>
      </div>
      <div className="section-icon-container">
        <span className="material-icons">{icon}</span>
      </div>
    </div>
  );
}

export default SectionCard;
