import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UpdateExtraImages, getSchools } from "../../api/flightSchoolApis";
import notifyToast from "../../components/Toast/Toast";
import { FieldArray, Form, Formik, Field } from "formik";
import ImageComponent from "../../components/DocumentComponent/ImageComponent";
import { pathConstants } from "../../util/constants";

const ExtraImages = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();

  const initialValues = useSelector((state) => state.flightSchool.data).filter(
    (item) => item.id === id,
  )[0];

  const [deletedImages, setdeletedImages] = useState([]);

  //  On Save basic info.
  const onSubmit = async (values) => {
    // For update basic info
    if (id) {
      try {
        const response = await UpdateExtraImages(
          values.extraImages,
          type,
          id,
          deletedImages,
        );
        notifyToast(true, response.message);
      } catch (error) {
        notifyToast(false, error);
      } finally {
        dispatch(
          getSchools(
            type === "flightSchool"
              ? pathConstants.flightSchoolDoc
              : pathConstants.typeRatingDoc,
          ),
        );
      }
    }
    // For save basic info
    else {
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      //   validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <Form>
        {/* Begin: Extra images */}
        <div className="flex flex-1 flex-col">
          <label className="col-form-label fw-bold fs-6">Extra Images</label>
          <FieldArray name="extraImages">
            {(fieldArrayProps) => {
              const { form, push, remove, insert } = fieldArrayProps;
              const { values } = form;
              const { extraImages } = values;
              return (
                <div className="grid grid-cols-1 md:grid-cols-3">
                  {extraImages?.length > 0 ? (
                    extraImages.map((item, index) => (
                      <div key={index} className="col">
                        <Field
                          type="file"
                          name={`extraImages[${index}]`}
                          className="col-6"
                        >
                          {(props) => (
                            <ImageComponent props={props} icon="image" />
                          )}
                        </Field>
                        <div className="flex flex-row">
                          <div className="">
                            <button
                              className="btn btn-danger btn-lg m-1"
                              onClick={() => {
                                remove(index);
                                setdeletedImages([
                                  ...deletedImages,
                                  extraImages[index],
                                ]);
                              }}
                              type="button"
                            >
                              -
                            </button>
                          </div>
                          <div className="">
                            <button
                              className="btn btn-success btn-lg m-1"
                              onClick={() => insert(index + 1, "")}
                              type="button"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <button
                      className="gradient-bg-success m-1 h-12 w-12 rounded-md p-1"
                      onClick={() => push("")}
                      type="button"
                    >
                      +
                    </button>
                  )}
                </div>
              );
            }}
          </FieldArray>
        </div>
        {/* End: extraImages */}
        <button
          className="gradient-bg fixed bottom-20 right-20 rounded-md p-2 text-white"
          type="submit"
        >
          Save
        </button>
      </Form>
    </Formik>
  );
};

export default ExtraImages;
