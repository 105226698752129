import React from "react";
import { useNavigate } from "react-router-dom";
import { deleteDocumentPaperwork } from "../../api/firestore";
import { deleteImage } from "../../api/storage";

function DocumentCard({ document, updateDocument, onOpenPreviewModal }) {
  const {
    title,
    description,
    urlToImage,
    id,
    documentHtml,
    documentHtmlBody,
    isScheduled,
    isPublished,
    scheduledDate,
  } = document;

  const navigate = useNavigate();

  const handleOnEditClick = () => {
    navigate("/documentPaperwork/editor", {
      state: {
        documentId: id,
        documentHtmlBody,
        title,
        description,
        urlToImage,
        isScheduled,
        isPublished,
      },
    });
  };

  const handleOnDeleteClick = async () => {
    try {
      await deleteDocumentPaperwork(id).then(() => {
        deleteImage(urlToImage);
      });
      updateDocument();
    } catch (error) {}
  };

  const handleOnPreviewClick = () => onOpenPreviewModal(documentHtml);

  return (
    <div className="pub-card">
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <img
          className="pub-card-img"
          alt="news-thumbnail-img"
          src={urlToImage}
        />
        <div style={{ margin: "0 20px" }}>
          <div
            style={{
              margin: 0,
              color: "#00158f",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            {title}
          </div>
          <div style={{ margin: 0, marginTop: "10px", fontWeight: 400 }}>
            {description}
          </div>
        </div>
      </div>
      <div className="pub-card-logo-div">
        {/* <a style={{ marginRight: 20, color: "#00158f" }}>18-sep-2021</a> */}
        {isScheduled && (
          <span
            className="material-icons pub-card-logo"
            style={{ cursor: "default" }}
            title={`This news is scheduled to publish on ${scheduledDate}`}
          >
            schedule
          </span>
        )}
        <span
          className="material-icons pub-card-logo"
          onClick={handleOnEditClick}
        >
          edit
        </span>
        <span
          className="material-icons pub-card-logo"
          onClick={handleOnPreviewClick}
        >
          visibility
        </span>
        <span
          className="material-icons pub-card-logo"
          style={{ color: "red" }}
          onClick={handleOnDeleteClick}
        >
          delete
        </span>
      </div>
    </div>
  );
}

export default DocumentCard;
