import React from "react";

function Loading() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <lottie-player
        src="https://assets4.lottiefiles.com/packages/lf20_ksvhsm9i.json"
        background="transparent"
        speed="1"
        style={{ width: 300, height: 150 }}
        autoplay
        loop
      ></lottie-player>
    </div>
  );
}

export default Loading;
