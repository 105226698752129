import { Button } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import ImageComponent from "../../components/DocumentComponent/ImageComponent";
import { pathConstants } from "../../util/constants";
import { useParams } from "react-router-dom";
import {
  UpdateBasicInfo,
  getFlightSchools,
  getSchools,
} from "../../api/flightSchoolApis";
import { useDispatch } from "react-redux";
import notifyToast from "../../components/Toast/Toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryToCurrency from "country-to-currency";

const BasicInfo = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();
  // Validation for fields
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    location: Yup.string().required("Required"),
    gmap: Yup.string().notRequired(),
    rating: Yup.number().required("Required"),
    fee: Yup.number().required("Required"),
    description: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    contact: Yup.string()

      .required("Required"),
    currency: Yup.string().required(),
  });

  const initialValues = useSelector((state) => state.flightSchool.data).filter(
    (item) => item.id === id,
  );

  //  On Save basic info.
  const onSubmit = async (values, actions) => {
    // For update basic info
    if (id) {
      try {
        console.log({ values });
        const response = await UpdateBasicInfo(
          values,
          type,
          id,
          initialValues[0],
        );

        notifyToast(true, response.message);
      } catch (error) {
        notifyToast(false, error);
      } finally {
        dispatch(
          getSchools(
            type === "flightSchool"
              ? pathConstants.flightSchoolDoc
              : pathConstants.typeRatingDoc,
          ),
        );
      }
    }
    // For save basic info
    else {
    }
  };

  return (
    <div className="card border-top flex flex-1 p-9">
      <Formik
        initialValues={initialValues[0]}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
            {/* Begin: name */}
            <div className="col-span-3 sm:col-span-2 md:col-span-1">
              <label className="col-form-label required fw-bold fs-6">
                School Name
              </label>
              <Field
                name="name"
                type="text"
                className="form-control form-control-lg form-control-solid"
                placeholder="School Name"
              />
              <ErrorMessage name="name">
                {(msg) => <div className="text-danger">{msg}</div>}
              </ErrorMessage>
            </div>
            {/* End: name */}

            {/* Phone Input */}

            {/* Begin: Contact */}
            <div className="col-span-4 md:col-span-1">
              <label className="col-form-label required fw-bold fs-6">
                School Contact
              </label>
              <Field
                name="contact"
                type="tel"
                className="form-control form-control-lg form-control-solid"
                placeholder="School Contact"
                // maxLength="10"
              >
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <PhoneInput
                    containerStyle={{ height: 50 }}
                    inputStyle={{
                      height: 50,
                      flex: 1,
                      display: "flex",
                      width: 200,
                    }}
                    country={"in"}
                    {...field}
                    onChange={(value, country) => {
                      setFieldValue("contact", value);
                      setFieldValue("country", country.name);

                      console.log(
                        countryToCurrency[country.countryCode.toUpperCase()],
                        country.countryCode,
                      );
                      setFieldValue(
                        "currency",
                        countryToCurrency[country.countryCode.toUpperCase()],
                      );
                    }}
                  />
                )}
              </Field>
              <ErrorMessage name="contact">
                {(msg) => <div className="text-danger">{msg}</div>}
              </ErrorMessage>
            </div>
            {/* End: Contact */}

            {/* Begin: Country */}
            <div className="col-span-3 sm:col-span-2 md:col-span-1">
              <label className="col-form-label required fw-bold fs-6">
                School Country
              </label>
              <Field
                name="country"
                type="text"
                // as="select"
                className="form-control form-control-lg form-control-solid"
                placeholder="School Country"
              >
                {/* <option key={"init"} value="">
                  Select country
                </option>
                {countryOptions.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))} */}
              </Field>
              <ErrorMessage name="country">
                {(msg) => <div className="text-danger">{msg}</div>}
              </ErrorMessage>
            </div>
            {/* End: Country */}

            {/* Begin: Location */}
            <div className="col-span-3 sm:col-span-2 md:col-span-1">
              <label className="col-form-label required fw-bold fs-6">
                School Location:
              </label>
              <Field
                name="location"
                type="text"
                className="form-control form-control-lg form-control-solid"
                placeholder="School Location"
              />
              <ErrorMessage name="location">
                {(msg) => <div className="text-danger">{msg}</div>}
              </ErrorMessage>
            </div>
            {/* End: Location */}

            {/* Begin: Gmap */}
            <div className="col-span-3 sm:col-span-2 md:col-span-2">
              <label className="col-form-label fw-bold fs-6">
                School Gmap link:
              </label>
              <Field
                name="gmap"
                type="text"
                className="form-control form-control-lg form-control-solid"
                placeholder="Link"
              />
              <ErrorMessage name="gmap">
                {(msg) => <div className="text-danger">{msg}</div>}
              </ErrorMessage>
            </div>
            {/* End: Gmap */}

            {/* Begin: Rating */}
            <div className="col-span-3 sm:col-span-2 md:col-span-1">
              <label className="col-form-label required fw-bold fs-6">
                School rating:
              </label>
              <Field
                name="rating"
                type="number"
                step="0.1"
                min={0}
                max={5}
                className="form-control form-control-lg form-control-solid"
                placeholder="Rating"
              />
              <ErrorMessage name="rating">
                {(msg) => <div className="text-danger">{msg}</div>}
              </ErrorMessage>
            </div>
            {/* End: Rating */}

            {/* Begin: Fees */}
            <div className="col-span-3 sm:col-span-2 md:col-span-1">
              <label className="col-form-label required fw-bold fs-6">
                School Fees:
              </label>
              <Field
                name="fee"
                type="number"
                min={0}
                className="form-control form-control-lg form-control-solid"
                placeholder="Fee"
              />
              <ErrorMessage name="fee">
                {(msg) => <div className="text-danger">{msg}</div>}
              </ErrorMessage>
            </div>
            {/* End: Fees */}

            {/* Begin: Currency */}
            <div className="col-span-3 sm:col-span-2 md:col-span-1">
              <label className="col-form-label required fw-bold fs-6">
                Currency
              </label>
              <Field
                name="currency"
                type="text"
                className="form-control form-control-lg form-control-solid"
                placeholder="Currency"
              />
              <ErrorMessage name="currency">
                {(msg) => <div className="text-danger">{msg}</div>}
              </ErrorMessage>
            </div>
            {/* End: currency */}

            {/* Begin: Description */}
            <div className="col-span-4">
              <label className="col-form-label required fw-bold fs-6">
                School Description:
              </label>
              <Field
                name="description"
                type="text"
                className="form-control form-control-lg form-control-solid"
                as="textarea"
                placeholder="School Description"
              />
              <ErrorMessage name="description">
                {(msg) => <div className="text-danger">{msg}</div>}
              </ErrorMessage>
            </div>
            {/* End: Description */}

            {/* Begin: Image */}
            <div className="col-span-3 sm:col-span-2 md:col-span-1">
              <label className="col-form-label required fw-bold fs-6">
                Title Image
              </label>
              <Field type="file" name="titleImage" className="col-6">
                {(props) => <ImageComponent props={props} icon="image" />}
              </Field>
              <ErrorMessage name="titleImage"></ErrorMessage>
            </div>
            {/* End: Image */}
          </div>
          <button
            className="gradient-bg fixed bottom-20 right-20 rounded-md p-2 text-white"
            // onClick={() => push("")}
            type="submit"
          >
            Save
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default BasicInfo;
