import React,{useState,useEffect} from 'react'
import './GroupsDetails.styles.css'
import { useLocation } from 'react-router-dom';
import firebase,{auth,db,storage,functions} from '../../lib/firebase';
import {getDocs,collection,query,where,updateDoc,doc,arrayUnion,arrayRemove} from 'firebase/firestore'
import ReactModal from 'react-modal';
import {ref,uploadBytes,getDownloadURL,deleteObject} from 'firebase/storage'
import {httpsCallable} from 'firebase/functions'
import GobackBtn from '../../components/GobackBtn/GobackBtn';


function MemCard(props){
    // const fullName = props.name.split(' ');
    // const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    // initials  = initials.toUpperCase();
    return(
        <div className="memcard-div">
             <span className="material-icons memcard-icon" style={{color:'var(--blue)'}}>person</span>
            <div style={{fontSize:20}}>{props.name}</div>
            <span className="material-icons memcard-icon" style={{color:'red'}} onClick={()=>props.deleteMember(props.uid)}>person_remove</span>
        </div>    
    )
}

function GroupsDetails() {
        const location =useLocation();
        console.log(location.state.id)

        const [classmatesArray,setClassmatesArray]=useState([]);
        const [isOpen,setIsOpen]=useState(false);
        const [isEditOpen,setIsEditOpen]=useState(false);
        const [error,setError]=useState('');
        const [email,setEmail]=useState('');
        const [isDisable,setIsDisable]=useState(true);
        const [image,setImage]=useState()
        const [nameI,setName]=useState('')
        const [descI,setDesc]=useState('')
        const [nameF,setNameF]=useState('')
        const [descF,setDescF]=useState('')
        const [imgurl,setImgurl]=useState()
        const [editBtn,setEditBtn]=useState(true)
        useEffect(()=>{
            setName(location.state.name);
            setNameF(location.state.name);
            setDesc(location.state.desc);
            setDescF(location.state.desc);
            setImgurl(location.state.imgurl);
            if(location.state.id!=null)
                 getClassmatesInfo(location.state.id);
        },[])
    
        
        

        //firebase fun to get classmates
        async function getClassmatesInfo(id){
            //const querySnapshot = await getDocs(collection(db,'classrooms'))
           const q= query(collection(db,'Users'),where('classrooms','array-contains',id))
           const querySnapshot=await getDocs(q);
            let array=[]
            querySnapshot.forEach(doc => {
                
                var customJson={
                    email:doc.data().email,
                    name:doc.data().name,
                    uid:doc.data().uid
                

                }
               // console.log("classmates :",customJson.classmates)
                array.push(customJson)
            });
        console.log("Query :",array)
        setClassmatesArray(array);
        }

        //create items array to dynamically deploy mem Array 
        const items= classmatesArray.map((items) => 
            // <li>{items.name}</li>
            <MemCard name={items.name} uid={items.uid} deleteMember={deleteMember}/>
            )

        //on click add member
        function onHandleAddMember(){
            setIsOpen(true)
        }   
       function onHandleCancelBtn(){
        setIsOpen(false)
       }
       //on click edit group
       function onHandleEditGroup(){
           setIsEditOpen(true);
       }

       //handle input
       function handleInput(input){
            if(input<1){
                setError('Email must not be null');
                setIsDisable(true);
            }
            else{
                setEmail(input);
                setError('');
                setIsDisable(false);
            }
       }
        
       function handleInputName(name){
           setNameF(name);
       }
       function handleInputDesc(desc){
        setDescF(desc);
        }

        function onHandleAddBtn(){
            console.log("email :",email);
            var checkIfUserExists = httpsCallable(functions,'checkIfUserExists');
             checkIfUserExists({ 
            email: email})
            .then(async (result)=>{
                console.log(result.data.userExists);
                //return;
                if(result.data.userExists){
                   
                    try {
                        //update user
                        console.log("location.state.id"+location.state.id)
                        await updateDoc(doc(db,'Users',result.data.id),{ classrooms:arrayUnion(location.state.id)})
                        //update classroom
                        await updateDoc(doc(db,`classrooms/${location.state.id}`),{
                            classmates:arrayUnion(result.data.id)
                        }).then(()=>{setIsOpen(false);getClassmatesInfo(location.state.id);console.log("updated successfully")})
                        
                    } catch (error) {
                        console.log("error at updatefireabse :",error);
                    }
                     
                }
            })
            .catch(e=>console.log(e))
            
        }

         //function to image upload
    function firebaseImageUpload(){
        console.log('firebaseImageUpload', image);

        if(!image){
            updateToFirebase();  
            setEditBtn(false)
            return 
        }
        console.log("image name :",image.name)

        setName(nameF);
        setDesc(descF);
        const storageRef = ref(storage,`/admin/groups/${location.state.id}/${location.state.imageId}`);
        // Delete the file
        deleteObject(storageRef).then(() => {
                // File deleted successfully
                //then upload new
                uploadBytes(storageRef, image).then((snapshot) => {
                    console.log('Uploaded a blob or file!');
                    getDownloadURL(snapshot.ref).then((downloadURL) => {
                      console.log('File available at', downloadURL);
                      setImgurl(downloadURL);
                      setEditBtn(false)
                    }).then(()=>{
                        
                        updateToFirebase();
                    })
                  });
            }).catch((error) => {
                // Uh-oh, an error occurred!
                console.log("unable to delete :",error)
            });
    
    }

    //update to firebase
    async function updateToFirebase(){
        const groupSnap= await updateDoc(doc(db,`classrooms/${location.state.id}`),{
            imageUrl:imgurl,
            name:nameF,
            description:descF,
        }).then(()=>{
            setIsEditOpen(false); 
            console.log("updated successfully");
            setName(nameF)
            setDesc(descF)
        })
    }
    
    
    //delete member
    async function  deleteMember(uid){
        try {
            //update user
            await updateDoc(doc(db,'Users',uid),{ classrooms:arrayRemove(location.state.id)})
            //update classroom
            await updateDoc(doc(db,`classrooms/${location.state.id}`),{
                classmates:arrayRemove(uid)
            }).then(()=>{getClassmatesInfo(location.state.id);console.log("Member Deleted successfully")})
            
        } catch (error) {
            console.log("error at updatefireabse :",error);
        }
    }
    return (
       <> 
       <GobackBtn/>
    <div className="details-main-div">
        <div className="details-card-div">
            <div>
                <div className="details-card-title">{nameI}</div>
                <div className="details-card-desc">{descI}</div>
                <div>
                    <button className="details-card-btn" onClick={onHandleEditGroup}>Edit Group Details</button>
                    <button className="details-card-btn" onClick={onHandleAddMember}>Add Members</button>
                </div>
            </div>
            <div>
                <img className="details-card-img" src={location.state.imgurl}/>
            </div>
        </div>
        <div className="details-memcard-div">
            <h3>Members :</h3>
            <ul className="grid-container">
                {items}
                </ul> 
            
        </div>

      <ReactModal  className="groups-modal" isOpen={isOpen}>
            <h1 style={{color:'var(--blue)'}}>Add New Member using email</h1>
            
            <input className="groups-modal-input" placeholder='email' onChange={event=>handleInput(event.target.value)} ></input>
            <span style={{ color: "red" }}>{error}</span>
            
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <button className="groups-modal-closebtn" onClick={onHandleCancelBtn}>Cancel</button>
            <button disabled={isDisable} className="groups-modal-addbtn" onClick={onHandleAddBtn}>Add</button>
            </div>
           
      </ReactModal>

      <ReactModal className= "groups-modal-edit" isOpen={isEditOpen}>
             <h1 style={{color:'var(--blue)'}}>Edit group details</h1>
             <div style={{display:'flex',flexDirection:'row',padding:30}}>
                <div style={{display:'flex',alignItems:'center'}}>Group Name :</div>
                <input className="groups-modal-input" placeholder='Group Name' defaultValue={location.state.name} onChange={event=>handleInputName(event.target.value)} ></input>    
             </div>
             <div style={{display:'flex',flexDirection:'row',padding:30}}>
                <div style={{display:'flex',alignItems:'center'}}>Group Description :</div>
                <input className="groups-modal-input" placeholder='Group Description' defaultValue={location.state.desc} onChange={event=>handleInputDesc(event.target.value)} ></input>    
             </div>
             <div style={{display:'flex',flexDirection:'row',padding:30}}>
                <div style={{display:'flex',alignItems:'center'}}>Group Icon :</div>
                <label className='groups-upload-btn' for='files'>+ Upload</label>
             <input id='files' accept='image/*' type='file' style={{visibility:'hidden'}} placeholder="upload" onChange={(e)=>{setImage(e.target.files[0]);setEditBtn(false);}}></input> 
               
             </div>
             <div style={{display:'flex',justifyContent:'space-between'}}>
             <button className="cancel-btn" onClick={()=>{setIsEditOpen(false)}}>cancel</button>
             <button className="save-edit-btn" onClick={firebaseImageUpload}>Save edit</button>
             </div>
      </ReactModal>
    </div>
    </>
    )
}

export default GroupsDetails
