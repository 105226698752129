import React, { useEffect, useRef } from "react";

import "./NavbarMenu.styles.css";
import { signOut } from "firebase/auth";
import { auth } from "../../lib/firebase";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setUser, setUserData } from "../../redux/slices/authSlice";

function MenuOption({ icon, text, onClick }) {
  return (
    <div onClick={onClick} className="menu-option">
      <span className="material-icons option-icon">{icon}</span>
      <div className="option-text">{text}</div>
    </div>
  );
}

function NavbarMenu({ isMenuVisible, onClickClose }) {
  const menuRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  }, [menuRef]);

  const handleClickOutside = (e) => {
    if (
      isMenuVisible &&
      menuRef &&
      menuRef.current &&
      !menuRef.current.contains(e.target)
    ) {
      handleOnClickCancel();
    }
  };

  const handleOnClickChangePassword = () => {
    console.log("handleOnClickChangePassword");
  };

  const handleOnClickLogout = async () => {
    console.log("handleOnClickLogout");
    try {
      await signOut(auth);
      dispatch(setUser(null));
      dispatch(setUserData(null));
      navigate("/login");
      console.log("signOut success");
    } catch (error) {
      console.log("handleOnClickLogout", error);
    }
  };

  const handleOnClickCancel = () => {
    onClickClose();
  };

  const menuOptionData = [
    // {
    //   icon: "lock",
    //   text: "Change Password",
    //   onClick: handleOnClickChangePassword,
    // },
    {
      icon: "logout",
      text: "Logout",
      onClick: handleOnClickLogout,
    },
    {
      icon: "close",
      text: "Cancel",
      onClick: handleOnClickCancel,
    },
  ];

  return (
    <div className="menu-container" ref={menuRef}>
      {menuOptionData.map(({ icon, text, onClick }, index) => (
        <MenuOption key={index} icon={icon} text={text} onClick={onClick} />
      ))}
    </div>
  );
}

export default NavbarMenu;
