import React from "react";
import { useNavigate } from "react-router-dom";
import { deleteNews } from "../../api/firestore";

function NewsCard({ news, updateNews, onOpenPreviewModal }) {
  const {
    title,
    description,
    urlToImage,
    id,
    newsHtml,
    newsHtmlBody,
    isScheduled,
    isPublished,
    scheduledDate,
  } = news;

  const navigate = useNavigate();

  const handleOnEditClick = () => {
    navigate("/news/editor", {
      state: {
        newsId: id,
        newsHtmlBody,
        title,
        description,
        urlToImage,
        isScheduled,
        isPublished,
      },
    });
  };

  const handleOnDeleteClick = async () => {
    try {
      await deleteNews(id, urlToImage);
      updateNews();
    } catch (error) {}
  };

  const handleOnPreviewClick = () => onOpenPreviewModal(newsHtml);

  return (
    <div className="card rounded-xl p-2 md:p-5">
      <div className="">
        <img
          className="float-end mx-2 h-28 w-28 rounded-xl"
          alt="news-thumbnail-img"
          src={urlToImage}
        />
        <div className="flex-col justify-start">
          <div className="text-xl font-medium md:text-3xl">{title}</div>
          <div style={{ margin: 0, marginTop: "10px", fontWeight: 400 }}>
            {description}
          </div>
        </div>
      </div>
      <div className="pub-card-logo-div">
        {/* <a style={{ marginRight: 20, color: "#00158f" }}>18-sep-2021</a> */}
        {isScheduled && (
          <span
            className="material-icons pub-card-logo"
            style={{ cursor: "default" }}
            title={`This news is scheduled to publish on ${scheduledDate}`}
          >
            schedule
          </span>
        )}
        <span
          className="material-icons pub-card-logo"
          onClick={handleOnEditClick}
        >
          edit
        </span>
        <span
          className="material-icons pub-card-logo"
          onClick={handleOnPreviewClick}
        >
          visibility
        </span>
        <span
          className="material-icons pub-card-logo"
          style={{ color: "red" }}
          onClick={handleOnDeleteClick}
        >
          delete
        </span>
      </div>
    </div>
  );
}

export default NewsCard;
