import React, {useRef} from 'react'

const FileComponent = ({props, icon}) => {
  const {form, field} = props
  const imgRef = useRef(null)
  const handleChange = (event) => {
    form.setFieldValue(field.name, event.target.files[0])
  }
  return (
    <div className='col-3'>
      <input
        key={field.name}
        type='file'
        name={field.name}
        ref={imgRef}
        onChange={handleChange}
        hidden
      />
      <button
        onClick={() => {
          imgRef.current.click()
        }}
        className='btn'
        type='button'
      >
        <span className='material-icons text-primary' style={{fontSize: '100px'}}>
          {icon}
        </span>
        {field.value ? <span className='bold'>{field.value.name}</span> : <></>}
      </button>
    </div>
  )
}

export default FileComponent
