import Grid from "@mui/material/Unstable_Grid2";
import { collection, doc, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import GobackBtn from "../../components/GobackBtn/GobackBtn";
import { db } from "../../lib/firebase";
import "./FlightSchool.styles.css";
import { addSchool } from "./schoolSlice";
import {
  deleteFlightSchool,
  deleteSchool,
  getSchools,
} from "../../api/flightSchoolApis";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { fSConstants, pathConstants } from "../../util/constants";
import { TbTrash, TbEdit } from "react-icons/tb";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import notifyToast from "../../components/Toast/Toast";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";

export default function School() {
  const { type } = useParams();
  const dispatch = useDispatch();

  const flightData = useSelector((state) => state.flightSchool.data);

  //use effect for getting flight schools
  useEffect(() => {
    dispatch(
      getSchools(
        type === "flightSchool"
          ? pathConstants.flightSchoolDoc
          : pathConstants.typeRatingDoc,
      ),
    );
  }, []);

  const navigate = useNavigate();
  return (
    <div>
      {/* go back btn */}
      <GobackBtn />

      <ToastContainer />

      {/* main content */}
      <div className="fs-main-div">
        {/* add new start */}
        <div className="card flex-col rounded-xl p-3 md:flex-row md:p-5">
          <div className="flex-col items-center">
            <h1 className="gradient-text text-2xl md:text-3xl">
              {type === "flightSchool"
                ? fSConstants.addFlightSchool
                : fSConstants.addTrSchool}
            </h1>
            <h3>
              {type === "flightSchool"
                ? fSConstants.descFSSchool
                : fSConstants.descTrSchool}
            </h3>
          </div>
          <div className="flex flex-1 items-center">
            <Button
              onClick={() => {
                const id = doc(
                  collection(db, pathConstants.flightSchoolDoc),
                ).id;
                const payload = initialValuesFS;
                payload["id"] = id;
                dispatch(addSchool(payload));
                navigate(`editor/${id}`);
              }}
              className="gradient-bg text-white"
            >
              Add +
            </Button>
            <lottie-player
              src="https://assets9.lottiefiles.com/private_files/lf30_CrFtpW.json"
              background="transparent"
              speed="1"
              style={{ width: 400, height: 200 }}
              autoplay
              loop
            ></lottie-player>
          </div>
        </div>
        {/* add new end */}
        <div className="fs-school-text">
          {type === "flightSchool"
            ? fSConstants.fsSchoolHeader
            : fSConstants.trSchoolHeader}
        </div>
        {/* School card start */}
        {!!flightData && (
          <Grid container spacing={2} columns={{ xs: 2, sm: 6, md: 8 }}>
            {/* <div className="fs-main-card"> */}
            {flightData.map((item, i) => (
              <Grid xs={2} sm={2} md={2} key={i}>
                <SchoolCard item={item} />
              </Grid>
            ))}
            {/* </div> */}
          </Grid>
        )}
        {/* card end */}
      </div>
      {/* main content end */}
    </div>
  );
}

const SchoolCard = (props) => {
  const { type } = useParams();
  const { name, description, titleImage, id } = props.item;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleSchoolClick() {
    navigate(`editor/${id}`, { state: { schoolData: props.item } });
  }
  const handleOnDeleteClick = async () => {
    handleClose();

    try {
      const response = await deleteSchool(id, type);
      notifyToast(true, response.message);
    } catch (error) {
      notifyToast(false, error);
    } finally {
      dispatch(
        getSchools(
          type === "flightSchool"
            ? pathConstants.flightSchoolDoc
            : pathConstants.typeRatingDoc,
        ),
      );
    }
  };

  return (
    <div className="card fs-card relative" key={id}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {fSConstants.popupTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {fSConstants.popupDesc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            className="hover:bg-red-600"
            onClick={handleOnDeleteClick}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <TbEdit
        className="text-bold absolute right-5 top-2 z-10 rounded-3xl bg-blue-500 p-1 text-3xl text-white"
        onClick={handleSchoolClick}
      />
      <TbTrash
        className="text-bold absolute right-5 top-12 z-10 rounded-3xl bg-red-500 p-1 text-3xl text-white"
        onClick={handleClickOpen}
      />
      <img src={titleImage} alt="" />

      <div className="p-2">
        <div className="text-lg font-medium md:text-xl">{name}</div>
        <div className="div2">{description}</div>
      </div>
    </div>
  );
};

const initialValuesFS = {
  contact: "",

  country: "",

  courseList: [
    {
      duration: "",
      fees: "",
      name: "",
      slots: "",
      description: "",
      document: "",
    },
  ],

  description: "",

  extraImages: [""],

  fee: "",

  location: "",

  gmap: "",

  name: "",

  rating: "",

  categoryRatings: [{ category: "", rating: "" }],

  reviewList: [{ author: "", content: "" }],

  titleImage: "",

  facilities: [""],
  currency: "",
};
