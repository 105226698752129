import React, { useState } from 'react'
import ReactModal from "react-modal";

import './PreviewModal.styles.css'

const screenSizes = {
    "Mobile S": '320px',
    "Mobile M": '375px',
    "Mobile L": '425px',
    "Tablet": '768px',
}

function PreviewModal({
    newsHtml,
    onClose
}) {

    const [screenSize, setScreenSize] = useState(screenSizes["Mobile S"])

    const handleOnSelectSize = (e) => {
        const screen = e.target.innerText;
        setScreenSize(screenSizes[screen])
    }

    return (
        <ReactModal
            isOpen
            className="preview modal-content"
            overlayClassName="preview modal-overlay"
            ariaHideApp={false}
        >
            <div  className="preview-container">
                <span className="material-icons close-icon"
                    onClick={onClose}
                >
                    close
                </span>
                <div style={{marginBottom: '10px'}}>
                    <button 
                        className={`screen-size-btn ${screenSize===screenSizes['Mobile S']?'is-selected':'not-selected'}`} 
                        onClick={handleOnSelectSize}>
                        Mobile S
                    </button>
                    <button 
                        className={`screen-size-btn ${screenSize===screenSizes['Mobile M']?'is-selected':'not-selected'}`} 
                        onClick={handleOnSelectSize}>
                        Mobile M
                    </button>
                    <button 
                        className={`screen-size-btn ${screenSize===screenSizes['Mobile L']?'is-selected':'not-selected'}`} 
                        onClick={handleOnSelectSize}>
                        Mobile L
                    </button>
                    <button 
                        className={`screen-size-btn ${screenSize===screenSizes['Tablet']?'is-selected':'not-selected'}`} 
                        onClick={handleOnSelectSize}>
                        Tablet
                    </button>
                </div>
                <div
                    className="html-preview"
                >
                    <iframe 
                        className="html-iframe"
                        style={{
                            width: screenSize
                        }}
                        srcDoc={newsHtml}
                    />
                </div>
            </div>

        </ReactModal>
    )
}

export default PreviewModal
