import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import Loading from "../components/Loading/Loading";

function ProtectedRoute({ children, ...rest }) {
  const user = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const location = useLocation();

  return !user ? (
    <Navigate
      to={{
        pathname: "/login",
        state: { from: location },
      }}
    />
  ) : isLoading ? (
    <Loading />
  ) : (
    children
  );

  // location.pathname === '/' || location.pathname === '/dashboard' ||  (userData && userData.priority!=undefined && userData.priority===0)?
  //   (children)
  // :
  // userData && !userData.priority &&
  //   userData.authorizedSections
  //   .includes(
  //     location
  //     .pathname
  //     .split("/")
  //     .filter((routeName) => routeName !== "")[0]
  //     )
  //     ?
  // (
  //   children
  // )
  // :
  // (
  //   <Redirect
  //     to={{
  //       pathname: "/dashboard",
  //       state: { from: location },
  //     }}
  //   />
  // )
}

export default ProtectedRoute;
