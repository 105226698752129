import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
const ErrorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-center ">
      <div className="card" style={{ width: "18rem" }}>
        <div className="card-body bg-info mt-30">
          <h2>Error: No Route found at {location.pathname}</h2>
          <button
            className="btn btn-primary"
            onClick={() => navigate("/dashboard")}
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
