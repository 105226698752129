import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { updateNews } from "../../api/firestore";
import { scheduleNews, sendNewsNotif } from "../../api/functions";
import { uploadImage } from "../../api/storage";
import Button from "../../components/Button/Button";
import GobackBtn from "../../components/GobackBtn/GobackBtn";
import LoadingLottie from "../../components/LoadingLottie/LoadingLottie";
import PreviewModal from "../../components/PreviewModal/PreviewModal";
import { getRandomId } from "../../util/randomId";
import "./NewsEditor.styles.css";

// import Moment from 'moment'
// import {
//     Timestamp
// } from 'firebase/firestore'

const loadingData = {
  save: {
    before: "Saving...",
    after: "News Saved!",
  },
  publish: {
    before: "Publishing...",
    after: "News Published!",
  },
  schedule: {
    before: "Scheduling...",
    after: "News Scheduled!",
  },
};

const CustomToolbarButton = ({
  OnPublishClick,
  OnSaveClick,
  OnScheduleClick,
  onPreviewClick,
  isScheduled,
  isPublished,
}) => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [date, setDate] = useState(null);

  const togglePickerVisibility = () => setIsPickerVisible(!isPickerVisible);

  const handleOnDateSelect = (date) => {
    // console.log('handleOnDateSelect', String(date.valueOf()))
    setDate(date);
  };

  return (
    <>
      <div className="editor-btn-wrapper">
        <span className="material-icons preview-btn" onClick={onPreviewClick}>
          visibility
        </span>
        <Button className="editor btn save" onClick={OnSaveClick}>
          Save
        </Button>
        {isScheduled || isPublished ? null : (
          <div style={{ position: "relative" }}>
            <Button
              className="editor btn schedule-news"
              onClick={togglePickerVisibility}
            >
              Schedule News
            </Button>
            <div
              className={`date-time-picker-container ${
                isPickerVisible ? "show" : ""
              }`}
            >
              <div className="date-time-picker">
                <div>Choose date & time</div>
                <Datetime input={false} onChange={handleOnDateSelect} />
                <Button
                  className="editor btn schedule"
                  disabled={!date}
                  onClick={() => {
                    togglePickerVisibility();
                    OnScheduleClick(date);
                  }}
                >
                  Schedule
                </Button>
              </div>
            </div>
          </div>
        )}
        {isScheduled || isPublished ? null : (
          <Button className="editor btn publish" onClick={OnPublishClick}>
            Publish
          </Button>
        )}
      </div>
    </>
  );
};

const htmlStart = `
<html><head><base href="https://pilots-universe.web.app/news/"><link type="text/css" rel="stylesheet" href="https://cdn.tiny.cloud/1/0pfglocrb6ay37gfbceng6dp8c8s77mc5qsj23i1avdtj60j/tinymce/5.9.2-118/skins/ui/oxide/content.min.css" crossorigin="anonymous"><link type="text/css" rel="stylesheet" href="https://cdn.tiny.cloud/1/0pfglocrb6ay37gfbceng6dp8c8s77mc5qsj23i1avdtj60j/tinymce/5.9.2-118/skins/content/default/content.min.css" crossorigin="anonymous"><meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"><style type="text/css">body { font-family:Helvetica,Arial,sans-serif; font-size:14px }</style></head><body id="tinymce" class="mce-content-body " data-new-gr-c-s-check-loaded="14.1033.0" data-gr-ext-installed="">`;

const htmlEnd = `</body></html>`;

function NewsEditor() {
  const editorRef = useRef(null);

  const [isInProgress, setIsInProgress] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState(null);
  const [isPreviewModalVisibile, setIsPreviewModalVisibile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    newsId,
    newsHtmlBody,
    title,
    description,
    urlToImage,
    isScheduled,
    isPublished,
  } = location.state
    ? location.state
    : {
        newsId: undefined,
        newsHtmlBody: undefined,
        title: undefined,
        description: undefined,
        urlToImage: undefined,
        isScheduled: undefined,
        isPublished: undefined,
      };
  const [htmlBody, setHtmlBody] = useState("<p>Start writing news...</p>");

  useEffect(() => {
    // console.log('NewsEditor moment', Moment.now());
    // console.log('NewsEditor Timestamp', Timestamp.now().toMillis());
    if (newsHtmlBody) setInitialHtml();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitialHtml = () => {
    setHtmlBody(newsHtmlBody);
  };

  const handleOnEditorStateChange = (newVal, editorState) => {
    setHtmlBody(newVal);
  };

  const handleOnSaveClick = () => {
    setIsInProgress(true);
    setLoadingMsg(loadingData.save.before);
    updateNews(
      {
        newsHtmlBody: htmlBody,
        newsHtml: htmlStart + htmlBody + htmlEnd,
      },
      newsId
    )
      .then(() => {
        handlePostLoading(loadingData.save.after);
        console.log("handleOnSaveClick success");
      })
      .catch((err) => {
        setIsInProgress(false);
        console.log("handleOnSaveClick ERR", err);
      });
  };

  const handlePostLoading = (loadedMsg) => {
    setIsInProgress(false);
    setLoaded(true);
    setLoadingMsg(loadedMsg);
    setTimeout(() => setLoaded(false), 2000);
  };

  const handleOnPublishClick = () => {
    setIsInProgress(true);
    setLoadingMsg(loadingData.publish.before);
    updateNews(
      {
        newsHtmlBody: htmlBody,
        newsHtml: htmlStart + htmlBody + htmlEnd,
        isPublished: true,
      },
      newsId
    )
      .then(async () => {
        console.log("handleOnPublishClick success");
        try {
          await sendNewsNotif(newsId, title, description, urlToImage);
          console.log("sendNewsNotif success");
        } catch (error) {
          console.log("sendNewsNotif error", error);
        }
        navigate(-1);
      })
      .catch((err) => {
        setIsInProgress(false);
        console.log("handleOnPublishClick ERR", err);
      });
  };

  const handleOnScheduleClick = (date) => {
    console.log("handleOnScheduleClick", date.toDate());
    console.log("handleOnScheduleClick", date.valueOf());
    setIsInProgress(true);
    setLoadingMsg(loadingData.schedule.before);
    scheduleNews(date.valueOf(), newsId, title, description, urlToImage)
      .then((result) => {
        console.log("handleOnScheduleClick", result);
        navigate(-1);
      })
      .catch((err) => {
        setIsInProgress(false);
        console.log("handleOnScheduleClick ERR", err);
      });
  };

  const handleOnPreviewClick = () => {
    setIsPreviewModalVisibile(true);
  };

  const handleOnClosePreviewModal = () => {
    setIsPreviewModalVisibile(false);
  };

  const uploadImageCallBack = (blobInfo, success, failure, progress) => {
    const file = blobInfo.blob();
    console.log("uploadImageCallBack", file);
    // return new Promise(
    //     (resolve,reject) => {
    uploadImage(
      file,
      `/admin/news/${getRandomId(6) + "@" + file.name}`,
      (url, err) => {
        console.log(url, "upload image callback", err);
        if (err) {
          failure(err);
          return;
        }
        success(url);
      }
    );
  };

  if (!newsId) {
    return <Navigate to="/news" />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "white",
        }}
      >
        <GobackBtn />
        <div className="loading-container">
          {isInProgress && (
            <>
              <LoadingLottie />
              <span>{loadingMsg}</span>
            </>
          )}
          {loaded && (
            <>
              <span className="material-icons success-icon">check_circle</span>
              <span>{loadingMsg}</span>
            </>
          )}
        </div>
        <CustomToolbarButton
          OnSaveClick={handleOnSaveClick}
          OnPublishClick={handleOnPublishClick}
          OnScheduleClick={handleOnScheduleClick}
          onPreviewClick={handleOnPreviewClick}
          isScheduled={isScheduled}
          isPublished={isPublished}
        />
      </div>
      <Editor
        apiKey="0pfglocrb6ay37gfbceng6dp8c8s77mc5qsj23i1avdtj60j"
        onInit={(evt, editor) => (editorRef.current = editor.getContent())}
        value={htmlBody}
        onEditorChange={handleOnEditorStateChange}
        init={{
          height: "100%",
          plugins: [
            "print paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
          ],
          menubar: "edit view insert format tools table help",
          toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | image | fullscreen | insertfile template link anchor codesample | charmap emoticons | lt,r rtl",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          file_picker_types: "image",
          image_caption: true,
          images_upload_handler: uploadImageCallBack,
        }}
      />
      {isPreviewModalVisibile && (
        <PreviewModal
          onClose={handleOnClosePreviewModal}
          newsHtml={htmlStart + htmlBody + htmlEnd}
        />
      )}
    </>
  );
}

export default NewsEditor;
