import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { db, storage } from "../lib/firebase";
import { pathConstants } from "../util/constants";
import { deleteImage, uploadFiles } from "./storage";
import notifyToast from "../components/Toast/Toast";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteObject, listAll, ref } from "firebase/storage";

// Update basic info
export const UpdateBasicInfo = async (basicInfo, type, id, initialValues) => {
  try {
    const docref = doc(
      db,
      type === "flightSchool"
        ? pathConstants.flightSchoolDoc
        : pathConstants.typeRatingDoc,
      id,
    );
    const { titleImage } = basicInfo;
    const basicInfoPayload = {
      name: basicInfo.name,
      location: basicInfo.location,
      gmap: basicInfo.gmap,
      description: basicInfo.description,
      rating: basicInfo.rating,
      contact: basicInfo.contact,
      fee: basicInfo.fee,
      country: basicInfo.country,
      currency: basicInfo.currency,
      titleImage: titleImage,
    };

    if (titleImage instanceof File) {
      // console.log("isfile", titleImage);
      const titleImgName = uuidv4();
      // Upload Title image
      await uploadFiles(
        titleImage,
        `${
          type === "flightSchool"
            ? pathConstants.flightSchoolStorage
            : pathConstants.typeRatingStorage
        }/${id}/${titleImgName}`,
      )
        .then(async (url) => {
          // console.log(initialValues, initialValues.titleImage);
          if (
            initialValues.titleImage != null &&
            initialValues.titleImage != ""
          ) {
            await deleteImage(initialValues.titleImage);
          }

          await setDoc(docref, {
            ...basicInfoPayload,
            titleImage: url,
          });

          notifyToast(true, "Title Image Saved");
        })
        .catch((err) => {
          throw err;
        });
    } else {
      await setDoc(docref, basicInfoPayload);
    }
    return { success: true, message: "Basic Info Saved successfully" };
  } catch (error) {
    throw error;
  }
};

// Update categories info
export const UpdateCategories = async (categories, type, id) => {
  try {
    const docref = doc(
      db,
      type === "flightSchool"
        ? pathConstants.flightSchoolDoc
        : pathConstants.typeRatingDoc,
      id,
    );

    const payload = {
      categoryRatings: categories,
    };

    await updateDoc(docref, payload);
    return { success: true, message: "Category Ratings Saved successfully" };
  } catch (error) {
    throw error;
  }
};

// Update  Review
export const UpdateReview = async (reviewList, type, id) => {
  try {
    const docref = doc(
      db,
      type === "flightSchool"
        ? pathConstants.flightSchoolDoc
        : pathConstants.typeRatingDoc,
      id,
    );

    const payload = {
      reviewList: reviewList,
    };

    await updateDoc(docref, payload);
    return { success: true, message: "Reviews Saved successfully" };
  } catch (error) {
    throw error;
  }
};

//   Update Facilities
export const UpdateFacilities = async (facilities, type, id) => {
  try {
    const docref = doc(
      db,
      type === "flightSchool"
        ? pathConstants.flightSchoolDoc
        : pathConstants.typeRatingDoc,
      id,
    );

    const payload = {
      facilities: facilities,
    };

    await updateDoc(docref, payload);
    return { success: true, message: "facilities Saved successfully" };
  } catch (error) {
    throw error;
  }
};

// Update Courses
export const UpdateCourses = async (courseList, type, id, deletedCourseUrl) => {
  try {
    const docref = doc(
      db,
      type === "flightSchool"
        ? pathConstants.flightSchoolDoc
        : pathConstants.typeRatingDoc,
      id,
    );
    var newCourseList = [];
    await Promise.all(
      courseList.map(async (value, index) => {
        var docUrl = value.docUrl;
        if (value.document != null && value.document !== "") {
          const pdfName = uuidv4();
          await uploadFiles(
            value.document,
            `${
              type === "flightSchool"
                ? pathConstants.flightSchoolStorage
                : pathConstants.typeRatingStorage
            }/${id}/${pdfName}`,
          ).then((url) => {
            docUrl = url;
            notifyToast(true, `Pdf uploaded successfully ${pdfName}`);
          });
        }
        const payload = {
          duration: value.duration,
          fees: value.fees,
          name: value.name,
          slots: value.slots,
          description: value.description,
          docUrl: docUrl,
        };
        newCourseList.push(payload);
      }),
    );
    await updateDoc(docref, {
      courseList: newCourseList,
    });

    await Promise.all(
      deletedCourseUrl.map(async (url) => {
        await deleteImage(url);
      }),
    );
    return { success: true, message: "Course list Saved successfully" };
  } catch (error) {
    throw error;
  }
};

// Update ExtraImages
export const UpdateExtraImages = async (
  extraImages,
  type,
  id,
  deletedImages,
) => {
  try {
    const docref = doc(
      db,
      type === "flightSchool"
        ? pathConstants.flightSchoolDoc
        : pathConstants.typeRatingDoc,
      id,
    );

    await Promise.all(
      deletedImages.map(async (value) => {
        await updateDoc(docref, {
          extraImages: arrayRemove(value),
        });
      }),
    );
    await Promise.all(
      extraImages.map((value, index) => {
        if (value instanceof File) {
          const imgName = uuidv4();
          // Upload extra image
          uploadFiles(
            value,
            `${
              type === "flightSchool"
                ? pathConstants.flightSchoolStorage
                : pathConstants.typeRatingStorage
            }/${id}/${imgName}`,
          )
            .then(async (url) => {
              await updateDoc(docref, { extraImages: arrayUnion(url) });
            })
            .catch((err) => {
              throw err;
            });
        }
      }),
    );

    return { success: true, message: "Images Saved successfully" };
  } catch (error) {
    throw error;
  }
};

//Firebase function to get Schools data
export const getSchools = createAsyncThunk(
  "schools/fetchData",
  async (collectionName) => {
    const querySnapshot = await getDocs(collection(db, collectionName));
    let array = [];
    querySnapshot.forEach((doc) => {
      let d = doc.data();
      var customJson = { ...doc.data(), id: doc.id };

      // console.log("classmates :",customJson.classmates)
      array.push(customJson);
    });

    return array;
  },
);

// Function to delete School and its images
export const deleteSchool = async (id, type) => {
  try {
    const docref = doc(
      db,
      type === "flightSchool"
        ? pathConstants.flightSchoolDoc
        : pathConstants.typeRatingDoc,
      id,
    );
    await deleteDoc(docref);

    // console.log(`${pathConstants.flightSchoolStorage}/${id}`);
    const storageRef = ref(
      storage,
      `${
        type === "flightSchool"
          ? pathConstants.flightSchoolStorage
          : pathConstants.typeRatingStorage
      }/${id}`,
    );

    await listAll(storageRef).then(async (listResults) => {
      const promises = listResults.items.map(async (item) => {
        return await deleteObject(item);
      });
      await Promise.all(promises);
    });

    return { success: true, message: "School deleted successfully." };
  } catch (error) {
    throw error;
  }
};
