import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import notifyToast from "../../components/Toast/Toast";
import {
  UpdateCategories,
  getFlightSchools,
  getSchools,
} from "../../api/flightSchoolApis";
import { pathConstants } from "../../util/constants";

const Categories = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();

  const initialValues = useSelector((state) => state.flightSchool.data).filter(
    (item) => item.id === id,
  )[0];
  console.log("initial value", initialValues);
  // Validation for fields
  const validationSchema = Yup.object({
    categoryRatings: Yup.array()
      .of(
        Yup.object().shape({
          category: Yup.string().min(4, "too short").required("Required"),
          rating: Yup.number().min(0).max(5).required("Required"),
        }),
      )
      .notRequired(),
  });

  //  On Save basic info.
  const onSubmit = async (values) => {
    // For update basic info
    if (id) {
      try {
        const response = await UpdateCategories(
          values.categoryRatings,
          type,
          id,
        );
        console.log({ values });
        notifyToast(true, response.message);
      } catch (error) {
        notifyToast(false, error);
      } finally {
        dispatch(
          getSchools(
            type === "flightSchool"
              ? pathConstants.flightSchoolDoc
              : pathConstants.typeRatingDoc,
          ),
        );
      }
    }
    // For save basic info
    else {
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="mb-6 p-3">
          {/* Begin: Rating */}
          <label className="col-lg-6 col-form-label required fw-bold fs-6">
            Category rating:
          </label>
          <FieldArray name="categoryRatings">
            {(fieldArrayProps) => {
              const { form, push, remove, insert } = fieldArrayProps;
              const { values } = form;
              const { categoryRatings } = values;
              return (
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                  {categoryRatings?.length > 0 ? (
                    categoryRatings.map((item, index) => (
                      <div key={index} className="grid grid-cols-2 gap-1">
                        <Field
                          name={`categoryRatings[${index}].category`}
                          type="text"
                          className="form-control form-control-lg form-control-solid col-span-8 m-1"
                          placeholder="Category"
                        />
                        <ErrorMessage
                          name={`categoryRatings[${index}].category`}
                        >
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                        <Field
                          name={`categoryRatings[${index}].rating`}
                          type="number"
                          min={0}
                          max={5}
                          placeholder="rating"
                          className="form-control form-control-lg form-control-solid col-span-2 m-1"
                        />
                        <ErrorMessage name={`categoryRatings[${index}].rating`}>
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>

                        <button
                          className="gradient-bg-danger m-1 h-12 w-12 rounded-md p-1"
                          onClick={() => remove(index)}
                          type="button"
                        >
                          -
                        </button>
                        <button
                          className="gradient-bg-success m-1 h-12 w-12 rounded-md p-1"
                          onClick={() =>
                            insert(index + 1, {
                              category: "",
                              rating: "",
                            })
                          }
                          type="button"
                        >
                          +
                        </button>
                      </div>
                    ))
                  ) : (
                    <button
                      className="gradient-bg-success m-1 h-12 w-12 rounded-md p-1"
                      onClick={() => push({ category: "", rating: "" })}
                    >
                      +
                    </button>
                  )}
                </div>
              );
            }}
          </FieldArray>
          {/* End: Rating */}
        </div>
        <button
          className="gradient-bg fixed bottom-20 right-20 rounded-md p-2 text-white"
          // onClick={() => push("")}
          type="submit"
        >
          Save
        </button>
      </Form>
    </Formik>
  );
};

export default Categories;
