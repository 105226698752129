import React, { useState } from "react";
import "react-dropdown/style.css";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { createDocumentPaperwork } from "../../api/firestore";
import { uploadImage } from "../../api/storage";
import { getRandomId } from "../../util/randomId";
import Button from "../Button/Button";
import LoadingLottie from "../LoadingLottie/LoadingLottie";
import "./CreateDocumentModal.style.css";

const documentDefaultInfo = {
  title: "",
  description: "",
  urlToImage: null,
  // category: null, createDocumentPaperwork
  isPublished: false,
};

const errors = {
  title: "Please add a title!",
  description: "A short description is needed!",
  urlToImage: "Please upload thumbnail image!",
  category: "Please choose a news category!",
};

function CreateDocumentModal({ onClose }) {
  const navigate = useNavigate();
  const [documentInfo, setDocumentInfo] = useState(documentDefaultInfo);
  const [error, setError] = useState(null);
  const [choosenImg, setChoosenImg] = useState(null);
  const [isInProgress, setIsInProgress] = useState(false);

  const handleOnClickProceed = () => {
    if (validateInfo(documentInfo)) {
      setIsInProgress(true);
      uploadImgToStorage();
    } else {
      console.log("CreateNewsModal not valid");
    }
  };

  const handleOnChange = (e) => {
    const info = { ...documentInfo, [e.target.name]: e.target.value };
    setDocumentInfo(info);
    validateInfo(info);
  };

  const validateInfo = (documentInfo, image) => {
    if (documentInfo.title === "") {
      setError(errors.title);
      return false;
    }
    if (documentInfo.description === "") {
      setError(errors.description);
      return false;
    }
    if (!choosenImg && !image) {
      setError(errors.urlToImage);
      return false;
    }
    // if(!documentInfo.category){
    //   setError(errors.category)
    //   return false;
    // }
    setError(null);
    return true;
  };

  const handleOnUploadClick = (e) => {
    if (isInProgress || e.target.files.length === 0) return;

    const image = e.target.files[0];
    setChoosenImg(image);
    validateInfo(documentInfo, image);
    console.log("handleOnUploadClick", image);
  };

  const uploadImgToStorage = () => {
    uploadImage(
      choosenImg,
      `/admin/documentpaperwork/${getRandomId(6) + "@" + choosenImg.name}`,
      (urlToImage, err) => {
        if (err) {
          setIsInProgress(false);
          console.log("handleOnUploadClick err", err);
          return;
        }
        const info = { ...documentInfo, urlToImage: urlToImage };
        setDocumentInfo(info);
        createDocumentDoc(info);
        console.log("handleOnUploadClick urlToImage", urlToImage);
      }
    );
  };

  const createDocumentDoc = (info) => {
    createDocumentPaperwork(info)
      .then((docRef) => {
        navigate("/documentPaperwork/editor", {
          state: {
            documentId: docRef.id,
            title: info.title,
            description: info.description,
            urlToImage: info.urlToImage,
          },
        });
      })
      .catch((err) => {
        setIsInProgress(false);
        console.log("createDocumentDoc error", err);
      });
  };

  // const handleOnDropdownSelect = (option) => {
  //   const info = { ...documentInfo, category: option.value }
  //   setDocumentInfo(info)
  //   validateInfo(info)
  // }

  return (
    <ReactModal
      isOpen
      className="modal-content"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <div className="modal-body">
        <div className="modal-title-container">
          <span className="material-icons modal-title-icon">article</span>
          <div className="modal-title">Create Document</div>
        </div>
        {error && <span className="proceed-error">{error}</span>}
        <form action="">
          <div className="modal-input-container">
            <div className="modal-input-title">1. Add title</div>
            <input
              placeholder="Type something..."
              type="text"
              className="modal-input"
              onChange={handleOnChange}
              name="title"
            />
          </div>
          <div className="modal-input-container">
            <div className="modal-input-title">2. Add description</div>
            <input
              placeholder="Type something..."
              type="text"
              className="modal-input"
              onChange={handleOnChange}
              name="description"
            />
          </div>
          <div className="modal-row">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="modal-input-title">3. Choose thumbnail image</div>
              <div style={{ marginTop: "10px", color: "var(--blue)" }}>
                {choosenImg ? choosenImg.name : "No File Choosen"}
              </div>
            </div>
            <label className="modal-btn image-upload-btn" htmlFor="files">
              Select
            </label>
            <input
              disabled={isInProgress}
              accept="image/*"
              id="files"
              type="file"
              style={{ display: "none" }}
              placeholder="upload"
              onChange={handleOnUploadClick}
            ></input>
          </div>
          <div className="modal-row">
            {/* <div className="modal-input-title">4. Choose Document Category</div>
            <ReactDropdown options={dropdownOptions} onChange={handleOnDropdownSelect}  placeholder="Select an option" /> */}
          </div>
        </form>
        <div className="modal-bottom-btn-container">
          <Button
            onClick={onClose}
            className=" modal cancel-btn"
            disabled={isInProgress}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOnClickProceed}
            className="modal proceed-btn"
            disabled={isInProgress}
          >
            {isInProgress ? <LoadingLottie /> : "Proceed"}
          </Button>
        </div>
      </div>
    </ReactModal>
  );
}
export default CreateDocumentModal;
