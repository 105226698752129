export const pathConstants = {
  flightSchoolDoc: "flightSchool",
  flightSchoolStorage: "admin/flightschools",
  typeRatingDoc: "trOrganisation",
  typeRatingStorage: "admin/trorganisation",
};

export const countryOptions = [
  "India",
  "USA",
  "Canada",
  "South Africa",
  "New Zealand",
];

export const fSConstants = {
  popupTitle: "Do you really want to delete?",
  popupDesc:
    "This will result in permanent removal and data cannot be retrived.",
  addFlightSchool: "Add Flight School",
  addTrSchool: "Add TypeRating",
  descFSSchool:
    "Add Details About FlightSchool, write review and add photographs",
  descTrSchool:
    "Add Details About Type rating, write review and add photographs",
  fsSchoolHeader: "All Flight Schools:",
  trSchoolHeader: "All Type Ratings:",
};
