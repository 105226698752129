import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { fetchDocuments } from "../../api/firestore";
import CreateDocumentModal from "../../components/CreateDocumentModal/CreateDocumentModal";
import GobackBtn from "../../components/GobackBtn/GobackBtn";
import Loading from "../../components/Loading/Loading";
import DocumentCard from "../../components/DocumentCard/DocumentCard";
import PreviewModal from "../../components/PreviewModal/PreviewModal";
import { checkAuthorization } from "../../lib/sectionAuthorization";
import "./DocumentAndPaperwork.style.css"; //fetchDocuments

function DocumentAndPaperwork() {
  const userData = useSelector((state) => state.auth.userData);
  const [isLoading, setIsLoading] = useState(true);
  const [previewHtml, setPreviewHtml] = useState(null);
  const [isModalVisibile, setIsModalVisibile] = useState(false);
  const [isPreviewModalVisibile, setIsPreviewModalVisibile] = useState(false);
  const [draftedDocument, setDraftedDocument] = useState([]);
  const [publishedDocument, setPublishedDocument] = useState([]);
  const navigate = useNavigate();

  const handleOnCreateDocumentClick = () => setIsModalVisibile(true);

  const handleOnCloseModal = () => setIsModalVisibile(false);
  const handleOnClosePreviewModal = () => {
    setIsPreviewModalVisibile(false);
    setPreviewHtml(null);
  };

  useEffect(() => {
    if (!userData) return;
    if (checkAuthorization(userData, "news")) {
      setIsLoading(false);
      getDocument(true);
      getDocument(false);
    } else {
      navigate("/dashboard");
    }
  }, [userData]);

  useEffect(() => {}, [isPreviewModalVisibile]);

  useEffect(() => {
    if (previewHtml && !isPreviewModalVisibile) {
      setIsPreviewModalVisibile(true);
    }
  }, [previewHtml]);

  const getDocument = (isPublished) => {
    fetchDocuments(isPublished, (documentArr, err) => {
      if (err) {
        console.log("getDocument error", err);
        return;
      }
      isPublished
        ? setPublishedDocument(documentArr)
        : setDraftedDocument(documentArr);
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="news-main-div">
      <GobackBtn />
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 10,
        }}
      >
        <div className="news-createnews-card">
          <div>
            <h1 className="news-create-text">Create Document Paperwork</h1>
            <p className="news-create-subtext">
              Add headline, insert images and write content for the documents
              you want...
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={handleOnCreateDocumentClick}
              className="news-createnews-btn"
            >
              Create
              <span className="material-icons">add</span>
            </button>
          </div>
          <lottie-player
            src="https://assets6.lottiefiles.com/packages/lf20_7smeegra.json"
            background="transparent"
            speed="1"
            style={{ width: 300, height: 150 }}
            autoplay
            loop
          ></lottie-player>
        </div>

        <Tabs>
          <TabList>
            <Tab>Published Documents</Tab>
            <Tab>Drafted Documents</Tab>
          </TabList>

          <TabPanel>
            {publishedDocument.map((document, index) => (
              <>
                <DocumentCard
                  key={index}
                  document={document}
                  updateDocument={() => getDocument(true)}
                  onOpenPreviewModal={(documentHtml) => {
                    // console.log('onOpenPreviewModal', documentHtml);
                    setPreviewHtml(documentHtml);
                  }}
                />
              </>
            ))}
          </TabPanel>
          <TabPanel>
            {draftedDocument.map((document, index) => (
              <DocumentCard
                key={index}
                document={document}
                updateDocument={() => getDocument(false)}
                onOpenPreviewModal={(documentHtml) => {
                  // console.log('onOpenPreviewModal', documentHtml);
                  setPreviewHtml(documentHtml);
                }}
              />
            ))}
          </TabPanel>
        </Tabs>
      </div>
      {isModalVisibile && <CreateDocumentModal onClose={handleOnCloseModal} />}
      {isPreviewModalVisibile && (
        <PreviewModal
          onClose={handleOnClosePreviewModal}
          newsHtml={previewHtml}
        />
      )}
    </div>
  );
}

export default DocumentAndPaperwork;
