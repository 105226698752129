import React from 'react'

function LoadingLottie() {
    return (
        <lottie-player
            src="https://assets3.lottiefiles.com/packages/lf20_7cccivs3.json"
            background="transparent"
            speed="1"
            style={{ width: 50, height: 50 }}
            autoplay
            loop
        />
    )
}

export default LoadingLottie
