import { createSlice } from "@reduxjs/toolkit";
import { getSchools } from "../../api/flightSchoolApis";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

// Create  school slice

export const schoolSlice = createSlice({
  name: "flightSchool",
  initialState,
  reducers: {
    addSchool: (state, action) => {
      state.data = [...state.data, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSchools.pending, (state, action) => {
        if (state.loading === false) {
          state.loading = true;
        }
      })
      .addCase(getSchools.fulfilled, (state, action) => {
        if (state.loading === true) {
          state.data = action.payload;
          state.loading = false;
        }
      })
      .addCase(getSchools.rejected, (state, action) => {
        if (state.loading === true) {
          state.loading = false;
          state.error = action.payload;
        }
      });
  },
});

// export actions
export const { getSchoolData, addSchool } = schoolSlice.actions;

export default schoolSlice.reducer;
