import React from "react";
import { useNavigate } from "react-router-dom";
import "./GobackBtn.styles.css";
function GobackBtn() {
  const navigate = useNavigate();
  return (
    <button
      className="gradient-bg mt-3 flex h-12 w-24 flex-1 items-center justify-center rounded-xl rounded-l-none p-1 pr-2 text-white"
      onClick={() => navigate(-1)}
    >
      <span className="material-icons icons text-sm">arrow_back_ios</span>
      Go Back
    </button>
  );
}

export default GobackBtn;
