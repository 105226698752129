import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import cloudImg from "../../assets/images/clouds.jpg";
import SectionCard from "../../components/SectionCard/SectionCard";
import "./Dashboard.styles.css";

const sectionData = [
  {
    title: "News",
    subTitle: "Create, Modify, Delete, Search, etc.",
    icon: "trending_up",
    route: "/news",
  },
  {
    title: "Groups",
    subTitle: "Create, Delete, Add participants, etc.",
    icon: "groups",
    route: "/groups",
  },
  {
    title: "Handbook",
    subTitle: "Create Handbooks",
    icon: "menu_book",
    route: "/handbookCreator",
  },
  {
    title: "FlightSchool",
    subTitle: "See all, Add new School, Delete, Modify, etc.",
    icon: "school",
    route: "/schools/flightSchool",
  },
  {
    title: "Type Rating",
    subTitle: "See all, Add new School, Delete, Modify, etc.",
    icon: "school",
    route: "/schools/typerating",
  },
  {
    title: "Document / Paperwork",
    subTitle: "See all, Add new School, Delete, Modify, etc.",
    icon: "school",
    route: "/documentPaperwork",
  },
];

function Dashboard() {
  const [authorizedSections, setAuthorizedSections] = useState([]);
  const userData = useSelector((state) => state.auth.userData);
  // console.log('Dashboard userData',userData);

  useEffect(() => {
    if (!userData) return;
    if (userData.priority !== undefined && userData.priority === 0) {
      setAuthorizedSections(sectionData);
    } else {
      let userAuthorizedSections = userData.authorizedSections
        ? userData.authorizedSections
        : [];
      console.log("userAuthorizedSections", userAuthorizedSections);
      userAuthorizedSections = sectionData.filter((section) =>
        userAuthorizedSections.includes(section.title.toLowerCase()),
      );
      // console.log('userAuthorizedSections final',userAuthorizedSections);
      setAuthorizedSections(userAuthorizedSections);
    }
  }, [userData]);

  return (
    <div className="flex flex-col justify-center">
      <div className="relative">
        <div className="z-1 absolute left-5 top-5 h-36 items-center">
          <div className="text-xl font-extrabold text-white md:text-5xl">
            Welcome Admin ! What's on your mind today ?
          </div>
        </div>
        <img className="h-36 w-full bg-cover" src={cloudImg} alt="clouds" />
      </div>
      <div className="flex items-center justify-center">
        <div className="grid-col-1 mt-10 grid gap-5 md:grid-cols-2 lg:grid-cols-3">
          {authorizedSections.map((data, index) => (
            <SectionCard
              key={index}
              icon={data.icon}
              title={data.title}
              subTitle={data.subTitle}
              route={data.route}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
