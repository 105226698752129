import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import LoadingLottie from "../../components/LoadingLottie/LoadingLottie";
import { auth, db } from "../../lib/firebase";
import store from "../../redux/store";
import "./Login.styles.css";
import { setUser } from "../../redux/actions/auth";

function Login() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  console.log({ user });
  const dispatch = useDispatch();
  const [userCred, setUserCred] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    email: false,
    password: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("Login | store", store.getState());
    if (user) {
      navigate("/dashboard");
    }
  }, [user]);

  const handleOnChange = (e) => {
    const tempUserCred = { ...userCred, [e.target.name]: e.target.value };
    setUserCred(tempUserCred);
    areCredsValid(tempUserCred);
  };

  const handleOnLoginClick = (e) => {
    e.preventDefault();

    if (areCredsValid(userCred)) {
      setIsLoading(true);
      firebaseAuth();
      console.log("all valid!");
    }
  };

  const areCredsValid = (tempUserCred) => {
    let isEmailValid = false;
    let isPwdValid = false;

    if (tempUserCred.email.length !== 0) isEmailValid = true;

    if (tempUserCred.password.length !== 0) isPwdValid = true;

    setError({ ...error, email: !isEmailValid, password: !isPwdValid });
    return isEmailValid && isPwdValid;
  };

  async function firebaseAuth() {
    const email = userCred.email;
    const password = userCred.password;
    try {
      const adminRef = collection(db, "adminAuth");
      const q = query(adminRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let array = [];
      querySnapshot.forEach((doc) => {
        array.push(doc.data());
      });

      if (array.length > 0) {
        console.log("admin exists!", email);
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            setIsLoading(false);
            const user = userCredential.user;
            dispatch(
              setUser({
                email: user.email,
                uid: user.uid,
                displayName: user.displayName,
                photoUrl: user.photoURL,
              }),
            );
            navigate("/dashboard");
          })
          .catch((error) => {
            setIsLoading(false);
            console.log("error in signinwithemail:", error);
          });
      } else {
        setIsLoading(false);
        console.log("admin doesnt exists:(");
      }
    } catch (e) {
      setIsLoading(false);
      console.error("Error adding document: ", e);
    }
  }

  return (
    <div className="flex flex-1">
      <img
        alt="login-image"
        className="flex w-max"
        src="https://images.unsplash.com/photo-1580832107357-012906efd981?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1888&q=80"
      />

      <div className="z-1 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
        <div className="login-card w-[300px] md:w-[500px]">
          <p className="login-text" style={{ textAlign: "center" }}>
            Login
          </p>
          <div className="login-divider-line"></div>
          <form>
            <div>
              <div className="input-container">
                <span className="material-icons login-icons">
                  alternate_email
                </span>
                <input
                  className="login-input"
                  name="email"
                  placeholder="Email"
                  required
                  onChange={handleOnChange}
                />
              </div>
              {error.email && (
                <span className="required-error">This field is required!</span>
              )}
            </div>

            <div>
              <div className="input-container">
                <span className="material-icons login-icons">lock</span>
                <input
                  className="login-input"
                  name="password"
                  placeholder="Password"
                  required
                  onChange={handleOnChange}
                />
              </div>
              {error.password && (
                <span className="required-error">This field is required!</span>
              )}
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className="login button"
                onClick={handleOnLoginClick}
                disabled={isLoading}
              >
                {isLoading ? <LoadingLottie /> : "Login"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
