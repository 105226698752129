import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";

import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { UpdateReview, getSchools } from "../../api/flightSchoolApis";
import notifyToast from "../../components/Toast/Toast";
import { pathConstants } from "../../util/constants";

const Reviews = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();

  const initialValues = useSelector((state) => state.flightSchool.data).filter(
    (item) => item.id === id,
  )[0];

  // Validation for fields
  const validationSchema = Yup.object({
    reviewList: Yup.array()
      .of(
        Yup.object().shape({
          author: Yup.string().required("Required"),
          content: Yup.string().min(4, "too short").required("Required"),
        }),
      )
      .notRequired(),
  });

  //  On Save basic info.
  const onSubmit = async (values) => {
    // For update basic info
    if (id) {
      try {
        const response = await UpdateReview(values.reviewList, type, id);
        notifyToast(true, response.message);
      } catch (error) {
        notifyToast(false, error);
      } finally {
        dispatch(
          getSchools(
            type === "flightSchool"
              ? pathConstants.flightSchoolDoc
              : pathConstants.typeRatingDoc,
          ),
        );
      }
    }
    // For save basic info
    else {
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="col-12 mb-6 p-3">
          {/* Begin: Reviews */}
          <label className="col-lg-6 col-form-label required fw-bold fs-6">
            Reviews:
          </label>
          <FieldArray name="reviewList">
            {(fieldArrayProps) => {
              const { form, push, remove, insert } = fieldArrayProps;
              const { values } = form;
              const { reviewList } = values;
              console.log({ reviewList });
              return (
                <div>
                  {reviewList?.length > 0 ? (
                    reviewList.map((item, index) => (
                      <div key={index} className="col">
                        <Field
                          name={`reviewList[${index}].author`}
                          type="text"
                          className="form-control form-control-lg form-control-solid col-3 m-1"
                          placeholder="Author"
                        />
                        <ErrorMessage name={`reviewList[${index}].author`}>
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                        <Field
                          name={`reviewList[${index}].content`}
                          type="text"
                          as="textarea"
                          className="form-control form-control-lg form-control-solid col-8 m-1"
                          placeholder="Content"
                        />
                        <ErrorMessage name={`reviewList[${index}].content`}>
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>

                        <button
                          className="gradient-bg-danger m-1 h-12 w-12 rounded-md p-1"
                          onClick={() => remove(index)}
                          type="button"
                        >
                          -
                        </button>
                        <button
                          className="gradient-bg-success m-1 h-12 w-12 rounded-md p-1"
                          onClick={() =>
                            insert(index, { author: "", content: "" })
                          }
                          type="button"
                        >
                          +
                        </button>
                      </div>
                    ))
                  ) : (
                    <button
                      className="gradient-bg-success m-1 h-12 w-12 rounded-md p-1"
                      onClick={() => push({ author: "", content: "" })}
                      type="button"
                    >
                      +
                    </button>
                  )}
                </div>
              );
            }}
          </FieldArray>
          {/* End: Reviews */}
        </div>
        <button
          className="gradient-bg fixed bottom-20 right-20 rounded-md p-2 text-white"
          // onClick={() => push("")}
          type="submit"
        >
          Save
        </button>
      </Form>
    </Formik>
  );
};

export default Reviews;
