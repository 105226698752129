import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import "./Navbar.styles.css";

function Navbar() {
  const userData = useSelector((state) => state.auth.userData);
  const name = userData ? userData.name : "";
  const [path, setPath] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let route = location.pathname
      .split("/")
      .filter((routeName) => routeName !== "")
      .map(
        (routeName) => routeName.charAt(0).toUpperCase() + routeName.slice(1),
      )
      .join(" > ");

    if (route !== "Dashboard" && route !== "") {
      route = " > " + route;
      setPath(route);
    } else setPath("");
  }, [location]);

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const handleOnProfileClick = () => setIsMenuVisible(true);

  return (
    <div className="z-1 flex h-20 max-h-32 justify-between shadow-lg">
      <div className="title-container">
        <span
          className="material-icons icon gradient-text"
          onClick={() =>
            location.pathname !== "/dashboard" ? navigate("/dashboard") : null
          }
        >
          flight
        </span>
        <div className="gradient-text overflow-clip font-semibold">{`Pilot's Universe${path}`}</div>
      </div>
      <div className="profile-container">
        {!isMenuVisible && userData && (
          <>
            <div className="name">{name}</div>
            <div onClick={handleOnProfileClick} className="profile">
              FL
            </div>
          </>
        )}
        {isMenuVisible && (
          <NavbarMenu
            isMenuVisible={isMenuVisible}
            onClickClose={() => setIsMenuVisible(false)}
          />
        )}
      </div>
    </div>
  );
}

export default Navbar;
