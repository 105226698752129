import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import FileComponent from "../../components/DocumentComponent/FileComponent";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  UpdateCourses,
  getFlightSchools,
  getSchools,
} from "../../api/flightSchoolApis";
import { pathConstants } from "../../util/constants";
import notifyToast from "../../components/Toast/Toast";

const Courses = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();

  const initialValues = useSelector((state) => state.flightSchool.data).filter(
    (item) => item.id === id,
  )[0];

  const [deletedCourseUrl, setdeletedCourseUrl] = useState([]);

  // Validation for fields
  const validationSchema = Yup.object({
    courseList: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Required"),
          description: Yup.string().min(4, "too short").required("Required"),
          slots: Yup.number().min(0).required("Required"),
          duration: Yup.string().required("Required"),
          fees: Yup.number().min(0).required("Required"),
          document: Yup.mixed()
            .nullable()
            .notRequired()
            .test(
              "FILE_SIZE",
              "Uploaded file is too big. Limit 10mb",
              (value) => !value || (value && value.size <= 10048000),
            )
            .test(
              "FILE_FORMAT",
              "Uploaded file has unsupported format.",
              (value) => !value || (value && value.type === "application/pdf"),
            ),
        }),
      )
      .required("Must have Courses") // these constraints are shown if and only if inner constraints are satisfied
      .min(1, "Minimum of 1 course"),
  });

  //  On Save basic info.
  const onSubmit = async (values) => {
    // For update basic info
    if (id) {
      try {
        const response = await UpdateCourses(
          values.courseList,
          type,
          id,
          deletedCourseUrl,
        );
        notifyToast(true, response.message);
      } catch (error) {
        notifyToast(false, error);
      } finally {
        dispatch(
          getSchools(
            type === "flightSchool"
              ? pathConstants.flightSchoolDoc
              : pathConstants.typeRatingDoc,
          ),
        );
      }
    }
    // For save basic info
    else {
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="col-12 mb-6 p-3">
          {/* Begin: Course Details */}
          <label className="col-lg-6 col-form-label required fw-bold fs-6">
            Course Details:
          </label>
          <FieldArray name="courseList">
            {(fieldArrayProps) => {
              const { form, push, remove, insert } = fieldArrayProps;
              const { values } = form;
              const { courseList } = values;
              return (
                <div>
                  {courseList?.length > 0 ? (
                    courseList.map((item, index) => (
                      <div key={index} className="row">
                        <Field
                          name={`courseList[${index}].name`}
                          type="text"
                          className="form-control form-control-lg form-control-solid col-3 m-1"
                          placeholder="Name"
                        />
                        <ErrorMessage name={`courseList[${index}].name`}>
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>

                        <Field
                          name={`courseList[${index}].slots`}
                          type="number"
                          min={0}
                          placeholder="Slots"
                          className="form-control form-control-lg form-control-solid col-3 m-1"
                        />
                        <ErrorMessage name={`courseList[${index}].slots`}>
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                        <Field
                          name={`courseList[${index}].duration`}
                          type="text"
                          placeholder="Duration"
                          className="form-control form-control-lg form-control-solid col-3 m-1"
                        />
                        <ErrorMessage name={`courseList[${index}].duration`}>
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                        <Field
                          name={`courseList[${index}].fees`}
                          type="number"
                          placeholder="Fees"
                          className="form-control form-control-lg form-control-solid col-2 m-1"
                        />
                        <ErrorMessage name={`courseList[${index}].fees`}>
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                        <Field
                          name={`courseList[${index}].description`}
                          type="textarea"
                          placeholder="description"
                          className="form-control form-control-lg form-control-solid col-8 m-1"
                        />
                        <ErrorMessage name={`courseList[${index}].description`}>
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                        {courseList[index].docUrl && (
                          <a
                            href={courseList[index].docUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="gradient-bg flex items-center justify-center rounded-lg p-2 text-white"
                          >
                            Preview Pdf
                          </a>
                        )}
                        <Field
                          type="file"
                          name={`courseList[${index}].document`}
                        >
                          {(props) => (
                            <FileComponent
                              props={props}
                              icon="picture_as_pdf"
                            />
                          )}
                        </Field>

                        <ErrorMessage name={`courseList[${index}].document`}>
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>

                        <button
                          className="gradient-bg-danger m-1 h-12 w-12 rounded-md p-1"
                          onClick={() => {
                            remove(index);
                            setdeletedCourseUrl([
                              ...deletedCourseUrl,
                              courseList[index].docUrl,
                            ]);
                          }}
                          type="button"
                        >
                          -
                        </button>
                        <button
                          className="gradient-bg-success m-1 h-12 w-12 rounded-md p-1"
                          onClick={() =>
                            insert(index + 1, {
                              duration: "",
                              fees: "",
                              name: "",
                              slots: "",
                              description: "",
                              document: "",
                            })
                          }
                          type="button"
                        >
                          +
                        </button>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <button
                      className="gradient-bg-success m-1 h-12 w-12 rounded-md p-1"
                      onClick={() =>
                        push({
                          duration: "",
                          fees: "",
                          name: "",
                          slots: "",
                          description: "",
                          document: "",
                        })
                      }
                      type="button"
                    >
                      +
                    </button>
                  )}
                </div>
              );
            }}
          </FieldArray>
          {/* End: Course Details */}
        </div>
        <button
          className="gradient-bg fixed bottom-20 right-20 rounded-md p-2 text-white"
          type="submit"
        >
          Save
        </button>
      </Form>
    </Formik>
  );
};

export default Courses;
