import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAt,
  updateDoc,
  where,
} from 'firebase/firestore'
import {db} from '../lib/firebase'
import {deleteImage} from './storage'

// Crud Operation News
export const createNews = (newsInfo) => addDoc(collection(db, 'adminNews'), newsInfo)

export const updateNews = (updatedNewsInfo, docId) =>
  updateDoc(doc(db, 'adminNews', docId), {
    ...updatedNewsInfo,
    publishedDate: Timestamp.now().toMillis(),
  })

export const deleteNews = async (docId, urlToImage) => {
  await deleteDoc(doc(db, 'adminNews', docId)).then(() => {
    deleteImage(urlToImage)
  })
}

export const fetchNews = async (isPublished, timestamp, cb) => {
  let q
  if (isPublished)
    q = query(
      collection(db, 'adminNews'),
      where('isPublished', '==', isPublished),
      orderBy('publishedDate', 'desc'),
      startAt(timestamp),
      limit(10)
    )
  else q = query(collection(db, 'adminNews'), where('isPublished', '==', isPublished))
  try {
    const querySnapshot = await getDocs(q)
    const docArr = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
    // console.log('fetchNews',docArr);
    cb(docArr, null)
  } catch (error) {
    cb([], error)
  }
}

// Crud operations on Document and Paperwork
export const createDocumentPaperwork = (newsInfo) =>
  addDoc(collection(db, 'documentPaperwork'), newsInfo)

export const deleteDocumentPaperwork = (docId) => deleteDoc(doc(db, 'documentPaperwork', docId))

export const updateDocumentPaperwork = (updatedNewsInfo, docId) =>
  updateDoc(doc(db, 'documentPaperwork', docId), {
    ...updatedNewsInfo,
    publishedDate: Timestamp.now().toMillis(),
  })

export const fetchDocuments = async (isPublished, cb) => {
  let q
  if (isPublished)
    q = query(
      collection(db, 'documentPaperwork'),
      where('isPublished', '==', isPublished),
      orderBy('publishedDate', 'desc')
    )
  else q = query(collection(db, 'documentPaperwork'), where('isPublished', '==', isPublished))
  try {
    const querySnapshot = await getDocs(q)
    const docArr = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
    // console.log('fetchNews',docArr);
    cb(docArr, null)
  } catch (error) {
    cb([], error)
  }
}

//Crud operations handbook
export const createHandbook = (handbookInfo) =>
  addDoc(collection(db, 'handbook', handbookInfo.handbookId, 'chapters'), handbookInfo)

export const updateHandbook = (updatedHandbookInfo, docId) =>
  updateDoc(doc(db, 'handbook', updatedHandbookInfo.handbookId, 'chapters', docId), {
    ...updatedHandbookInfo,
    publishedDate: Timestamp.now().toMillis(),
  })

export const deleteHandbook = (handbookId, docId) =>
  deleteDoc(doc(db, 'handbook', handbookId, 'chapters', docId))

export const fetchHandbook = async (isPublished, handbookId, cb) => {
  let q
  if (isPublished)
    q = query(
      collection(db, 'handbook', handbookId, 'chapters'),
      where('isPublished', '==', isPublished),
      orderBy('publishedDate', 'desc')
    )
  else
    q = query(
      collection(db, 'handbook', handbookId, 'chapters'),
      where('isPublished', '==', isPublished)
    )
  try {
    const querySnapshot = await getDocs(q)
    const docArr = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
    // console.log('fetchNews',docArr);
    cb(docArr, null)
  } catch (error) {
    cb([], error)
  }
}

//Fetch user
export const fetchUserData = (uid) => getDoc(doc(db, 'adminAuth', uid))
