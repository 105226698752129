import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { fetchNews } from "../../api/firestore";
import CreateNewsModal from "../../components/CreateNewsModal/CreateNewsModal";
import GobackBtn from "../../components/GobackBtn/GobackBtn";
import Loading from "../../components/Loading/Loading";
import NewsCard from "../../components/NewsCard/NewsCard";
import PreviewModal from "../../components/PreviewModal/PreviewModal";
import { checkAuthorization } from "../../lib/sectionAuthorization";

import "./News.styles.css";

function News() {
  const userData = useSelector((state) => state.auth.userData);
  const [isLoading, setIsLoading] = useState(true);
  const [previewHtml, setPreviewHtml] = useState(null);
  const [isModalVisibile, setIsModalVisibile] = useState(false);
  const [isPreviewModalVisibile, setIsPreviewModalVisibile] = useState(false);
  const [draftedNews, setDraftedNews] = useState([]);
  const [publishedNews, setPublishedNews] = useState([]);
  const [timestamp, setTimestamp] = useState(Date.now());
  const navigate = useNavigate();

  const handleOnCreateNewsClick = () => setIsModalVisibile(true);

  const handleOnCloseModal = () => setIsModalVisibile(false);
  const handleOnClosePreviewModal = () => {
    setIsPreviewModalVisibile(false);
    setPreviewHtml(null);
  };

  useEffect(() => {
    if (!userData) return;
    if (checkAuthorization(userData, "news")) {
      setIsLoading(false);
      getNews(true);
      getNews(false);
    } else {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {}, [isPreviewModalVisibile]);

  useEffect(() => {
    if (previewHtml && !isPreviewModalVisibile) {
      setIsPreviewModalVisibile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewHtml]);

  const getNews = (isPublished) => {
    fetchNews(isPublished, timestamp, (newsArr, err) => {
      if (err) {
        console.log("getNews error", err);
        return;
      }
      isPublished
        ? setPublishedNews([...publishedNews, ...newsArr])
        : setDraftedNews(newsArr);
      setTimestamp(newsArr[newsArr.length - 1].publishedDate);
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="news-main-div">
      <GobackBtn />
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 10,
        }}
      >
        <div className="card flex flex-1 rounded-xl p-3">
          <div className="flex flex-col items-center">
            <h1 className="gradient-text text-3xl">Create News</h1>
            <p className="text-lg">
              Add headline, insert images and write content for the news you
              want...
            </p>
          </div>
          <div className="flex flex-1 justify-center">
            <button
              onClick={handleOnCreateNewsClick}
              className="gradient-bg flex h-12 self-center rounded-xl p-2 font-bold text-white"
            >
              Create
              <span className="material-icons">add</span>
            </button>
            <lottie-player
              src="https://assets6.lottiefiles.com/packages/lf20_7smeegra.json"
              background="transparent"
              speed="1"
              style={{ width: 300, height: 150 }}
              autoplay
              loop
            ></lottie-player>
          </div>
        </div>

        <Tabs className="mt-2">
          <TabList className="m-2 flex justify-around">
            <Tab className="rounded-xl p-2 text-lg md:text-2xl">
              Published News
            </Tab>
            <Tab className="rounded-xl p-2 text-lg md:text-2xl">
              Drafted News
            </Tab>
          </TabList>

          <TabPanel>
            {publishedNews.map((news, index) => (
              <NewsCard
                key={index}
                news={news}
                updateNews={() => getNews(true)}
                onOpenPreviewModal={(newsHtml) => {
                  // console.log('onOpenPreviewModal', newsHtml);
                  setPreviewHtml(newsHtml);
                }}
              />
            ))}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span
                className="material-icons load-more-btn"
                onClick={() => getNews(true)}
              >
                sync
              </span>
            </div>
          </TabPanel>
          <TabPanel>
            {draftedNews.map((news, index) => (
              <NewsCard
                key={index}
                news={news}
                updateNews={() => getNews(false)}
                onOpenPreviewModal={(newsHtml) => {
                  // console.log('onOpenPreviewModal', newsHtml);
                  setPreviewHtml(newsHtml);
                }}
              />
            ))}
          </TabPanel>
        </Tabs>
      </div>
      {isModalVisibile && <CreateNewsModal onClose={handleOnCloseModal} />}
      {isPreviewModalVisibile && (
        <PreviewModal
          onClose={handleOnClosePreviewModal}
          newsHtml={previewHtml}
        />
      )}
    </div>
  );
}

export default News;
