import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { fetchUserData } from "./api/firestore";
import Navbar from "./components/Navbar/Navbar";
import ProtectedRoute from "./lib/ProtectedRoute.jsx";
import { auth } from "./lib/firebase";
import Dashboard from "./page/Dashboard/Dashboard";
import DocumentAndPaperwork from "./page/DocumentAndPaperwork/DocumentAndPaperwork";
import DocumentEditor from "./page/DocumentEditor/DocumentEditor";
import FlightSchool from "./page/School/School.jsx";
import FlightSchoolEdit from "./page/School/SchoolEdit.jsx";
import Groups from "./page/Groups/Groups";
import GroupsDetails from "./page/GroupsDetails/GroupsDetails";
import Handbook from "./page/Handbook/Handbook";
import HandbookCreator from "./page/HandbookCreator/HandbookCreator";
import HandbookEditor from "./page/HandbookEditor/HandbookEditor";
import ErrorPage from "./page/Login/ErrorPage.jsx";
import Login from "./page/Login/Login";
import News from "./page/News/News";
import NewsEditor from "./page/NewsEditor/NewsEditor";
import TypeRating from "./page/TypeRating/TypeRating";
import TypeRatingEdit from "./page/TypeRating/TypeRatingEdit.jsx";
import {
  setIsLoading,
  setUser,
  setUserData,
} from "./redux/slices/authSlice.js";
import store from "./redux/store.js";
import School from "./page/School/School.jsx";
import SchoolEdit from "./page/School/SchoolEdit.jsx";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getUserData(user.uid);
        dispatch(
          setUser({
            email: user.email,
            uid: user.uid,
            displayName: user.displayName,
            photoUrl: user.photoURL,
          }),
        );
        dispatch(setIsLoading(false));
      }
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserData = (uid) => {
    fetchUserData(uid)
      .then((docSnap) => {
        // console.log('fetchUserData success', docSnap.data());
        dispatch(setUserData(docSnap.data()));
      })
      .catch((error) => {
        console.log("error in fetchUserData", error);
      });
  };

  return (
    <Router className="flex">
      <div className="flex flex-col">
        <Navbar />
        <Routes>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/news"
            element={
              <ProtectedRoute>
                <News />
              </ProtectedRoute>
            }
          />
          <Route
            path="/news/editor"
            element={
              <ProtectedRoute>
                <NewsEditor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/handbookCreator/handbook"
            element={
              <ProtectedRoute>
                <Handbook />
              </ProtectedRoute>
            }
          />
          <Route
            path="/handbookCreator"
            element={
              <ProtectedRoute>
                <HandbookCreator />
              </ProtectedRoute>
            }
          />
          <Route
            path="/handbookCreator/handbook/editor"
            element={
              <ProtectedRoute>
                <HandbookEditor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groups"
            element={
              <ProtectedRoute>
                <Groups />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groups/details"
            element={
              <ProtectedRoute>
                <GroupsDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/schools/:type"
            element={
              <ProtectedRoute>
                <School />
              </ProtectedRoute>
            }
          />
          <Route
            path="/schools/:type/editor"
            element={
              <ProtectedRoute>
                <SchoolEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/schools/:type/editor/:id"
            element={
              <ProtectedRoute>
                <SchoolEdit />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/typeRating"
            element={
              <ProtectedRoute>
                <TypeRating />
              </ProtectedRoute>
            }
          /> */}
          {/* <Route
            path="/typeRating/editor"
            element={
              <ProtectedRoute>
                <TypeRatingEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/typeRating/editor/:id"
            element={
              <ProtectedRoute>
                <TypeRatingEdit />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/documentPaperwork"
            element={
              <ProtectedRoute>
                <DocumentAndPaperwork />
              </ProtectedRoute>
            }
          />
          <Route
            path="/documentPaperwork/editor"
            element={
              <ProtectedRoute>
                <DocumentEditor />
              </ProtectedRoute>
            }
          />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
