import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    user: null,
    userData: null,
    isLoading: true
}

export const authSlice = createSlice({
    name: 'auth', initialState,
    reducers: {
        setUser: (state, user) => {
            state.user = user.payload
        },
        setUserData: (state, userData) => {
            state.userData = userData.payload
        },
        setIsLoading: (state, isLoading) => {
            state.isLoading = isLoading.payload
        },
    }
})

// export actions
export const { setUser,
    setUserData,
    setIsLoading } = authSlice.actions

export default authSlice.reducer