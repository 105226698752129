import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {getAuth} from "firebase/auth"
import {getStorage} from "firebase/storage"
import {getFunctions} from "firebase/functions"

const firebaseConfig = {
  apiKey: "AIzaSyCLFSGC_ONefxkXKiJDXF53QnZchy5Bmb4",
  authDomain: "pilots-universe.firebaseapp.com",
  projectId: "pilots-universe",
  storageBucket: "pilots-universe.appspot.com",
  messagingSenderId: "857093686026",
  appId: "1:857093686026:web:7ac1919aba081af39c70f3",
  measurementId: "G-PFLGE6TELZ"
};

const app=initializeApp(firebaseConfig);
export const db =getFirestore(app);
export const auth =getAuth(app)
export const storage =getStorage(app)
export const functions =getFunctions(app)
export default app;