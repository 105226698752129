import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { clsx } from "clsx";

import {
  saveFlightSchool,
  updateFlightSchool,
} from "../../api/flightSchoolApis";
import BasicInfo from "./BasicInfo";
import Categories from "./Categories";
import Courses from "./Courses";
import Facilities from "./Facilities";
import Reviews from "./Reviews";
import GobackBtn from "../../components/GobackBtn/GobackBtn";
import ExtraImages from "./ExtraImages";

const SchoolEdit = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabsData = [
    {
      tabName: "Basic Info",
      tabElement: <BasicInfo />,
    },
    {
      tabName: "Categories",
      tabElement: <Categories />,
    },
    {
      tabName: "Reviews",
      tabElement: <Reviews />,
    },
    {
      tabName: "Facilities",
      tabElement: <Facilities />,
    },
    {
      tabName: "Courses",
      tabElement: <Courses />,
    },
    {
      tabName: "Extra Images",
      tabElement: <ExtraImages />,
    },
  ];

  return (
    <div className="bg-light-secondary relative">
      {/* Notify toast container */}
      <ToastContainer />
      <GobackBtn />

      <div className="card m-3 flex p-1">
        <div className="flex flex-wrap p-3">
          {tabsData.map((tab, index) => {
            return (
              <div
                key={index}
                className="m-1"
                onClick={() => setSelectedTab(index)}
              >
                <div
                  className={clsx(
                    "rounded-xl bg-slate-300 p-2",
                    selectedTab === index && "gradient-bg p-2 text-white",
                  )}
                >
                  {tab.tabName}
                </div>
              </div>
            );
          })}
        </div>
        {tabsData[selectedTab].tabElement}
      </div>
    </div>
  );
};

export default SchoolEdit;
