import React, { useRef } from "react";

const ImageComponent = ({ props, icon }) => {
  const { form, field } = props;
  const imgRef = useRef(null);
  const handleChange = (event) => {
    form.setFieldValue(field.name, event.target.files[0]);
  };

  const onButtonClick = () => {
    imgRef.current.click();
  };

  return (
    <div>
      <input
        type="file"
        name={field.name}
        ref={imgRef}
        onChange={handleChange}
        hidden
      />
      <button onClick={onButtonClick} className="btn" type="button">
        {field.value ? (
          <div className="flex w-[250px] flex-col">
            <img
              src={
                typeof field.value === "string"
                  ? field.value
                  : URL.createObjectURL(field.value)
              }
              style={{ width: "250px", height: "250px" }}
              className="rounded border"
            />
            <span className="bold overflow-hidden">{field.value.name}</span>
          </div>
        ) : (
          <span
            className="material-icons text-primary"
            style={{ fontSize: "250px" }}
          >
            {icon}
          </span>
        )}
      </button>
    </div>
  );
};

export default ImageComponent;
