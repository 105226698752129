import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { fetchHandbook } from "../../api/firestore";
import CreateHandbookModal from "../../components/CreateHandbookModal/CreateHandbookModal";
import GobackBtn from "../../components/GobackBtn/GobackBtn";
import HandbookCard from "../../components/HandbookCard/HandbookCard";
import Loading from "../../components/Loading/Loading";
import PreviewModal from "../../components/PreviewModal/PreviewModal";
import { checkAuthorization } from "../../lib/sectionAuthorization";

import { Button } from "@mui/material";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../lib/firebase";
import "./Handbook.styles.css";

function Handbook({ props }) {
  const userData = useSelector((state) => state.auth.userData);
  const [isLoading, setIsLoading] = useState(true);
  const [previewHtml, setPreviewHtml] = useState(null);
  const [isModalVisibile, setIsModalVisibile] = useState(false);
  const [isPreviewModalVisibile, setIsPreviewModalVisibile] = useState(false);
  const [draftedNews, setDraftedNews] = useState([]);
  const [publishedNews, setPublishedNews] = useState([]);
  const [editActive, setEditActive] = useState(false);
  const [whatsNew, setWhatsNew] = useState(["save", "upto", "3 New"]);
  const navigate = useNavigate();

  const handleOnCreateNewsClick = () => setIsModalVisibile(true);

  const handleOnCloseModal = () => setIsModalVisibile(false);
  const handleOnClosePreviewModal = () => {
    setIsPreviewModalVisibile(false);
    setPreviewHtml(null);
  };
  const handbookId = useLocation().state.id;
  const handbookTitle = useLocation().state.name;
  const handbookImg = useLocation().state.imgurl;
  useEffect(() => {
    if (!userData) return;
    if (checkAuthorization(userData, "handbook")) {
      setIsLoading(false);
      getHandbooks(true);
      getHandbooks(false);
    } else {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {}, [isPreviewModalVisibile]);

  useEffect(() => {
    if (previewHtml && !isPreviewModalVisibile) {
      setIsPreviewModalVisibile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewHtml]);

  const getHandbooks = (isPublished) => {
    console.log("I am called");
    fetchHandbook(isPublished, handbookId, (newsArr, err) => {
      if (err) {
        console.log("getHandbooks error", err);
        return;
      }
      isPublished ? setPublishedNews(newsArr) : setDraftedNews(newsArr);
    });
  };
  const onClickSaveWhatsNew = async () => {
    setEditActive(false);
    console.log("WhatsNew :", whatsNew);
    await updateDoc(doc(db, "handbook", handbookId), {
      whatsNew,
    });
  };

  const onDeleteHandbook = async () => {
    await deleteDoc(doc(db, "handbook", handbookId))
      .then(() => {
        console.log("Deleted handbook");
      })
      .catch((err) => console.log("Error: ", err));
    navigate(-1);
  };

  const fetchdata = async () => {
    let d1 = await (await getDoc(doc(db, "handbook", handbookId))).data()
      .whatsNew;
    if (typeof d1 !== "undefined") setWhatsNew(d1);
  };
  useEffect(() => {
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeWhatsNew = (data, i) => {
    let newArray = whatsNew;
    newArray[i] = data;
    setWhatsNew(newArray);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="news-main-div">
      <GobackBtn />
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 10,
        }}
      >
        <div className="news-createnews-card">
          <img
            src={handbookImg}
            alt=""
            style={{ borderRadius: 20, width: "30rem", height: "20rem" }}
          />
          <div
            style={{ marginLeft: 20, display: "flex", flexDirection: "column" }}
          >
            <div className="news-create-text">{handbookTitle}</div>
            <div className="news-create-subtext">handbookDesc</div>
            {/* Whats new div */}
            <div>
              <p>What's New?</p>
              {editActive ? (
                <div className="whats-new-div">
                  <input
                    type="text"
                    placeholder="New Change 1"
                    onChange={(e) => onChangeWhatsNew(e.target.value, 0)}
                  />
                  <input
                    type="text"
                    placeholder="New Change 2"
                    onChange={(e) => onChangeWhatsNew(e.target.value, 1)}
                  />
                  <input
                    type="text"
                    placeholder="New Change 3"
                    onChange={(e) => onChangeWhatsNew(e.target.value, 2)}
                  />
                  <div
                    className="material-icons pub-card-logo"
                    style={{ padding: 5, width: 25 }}
                    onClick={onClickSaveWhatsNew}
                  >
                    save
                  </div>
                </div>
              ) : (
                <div className="whats-new-div">
                  <div>1) {whatsNew[0]}</div>
                  <div>2) {whatsNew[1]}</div>
                  <div>3) {whatsNew[2]}</div>

                  <div
                    className="material-icons pub-card-logo"
                    style={{ padding: 5, width: 25 }}
                    onClick={() => setEditActive(true)}
                  >
                    edit
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="handbook-delete-btn">
            <Button onClick={onDeleteHandbook} variant="contained">
              Delete Handbook
            </Button>
          </div>
        </div>

        <Tabs>
          <TabList>
            <Tab>Published Handbooks</Tab>
            <Tab>Drafted Handbooks</Tab>
          </TabList>

          <TabPanel>
            {publishedNews.map((news, index) => (
              <HandbookCard
                key={index}
                handbook={news}
                updateHandbook={() => getHandbooks(true)}
                onOpenPreviewModal={(newsHtml) => {
                  // console.log('onOpenPreviewModal', newsHtml);
                  setPreviewHtml(newsHtml);
                }}
              />
            ))}
          </TabPanel>
          <TabPanel>
            {draftedNews.map((news, index) => (
              <HandbookCard
                key={index}
                handbook={news}
                updateHandbook={() => getHandbooks(false)}
                onOpenPreviewModal={(newsHtml) => {
                  // console.log('onOpenPreviewModal', newsHtml);
                  setPreviewHtml(newsHtml);
                }}
              />
            ))}
          </TabPanel>
        </Tabs>
        <div onClick={handleOnCreateNewsClick} className="handbook-create-btn">
          <div>+ Add Chapter</div>
        </div>
      </div>
      {isModalVisibile && (
        <CreateHandbookModal
          handbookId={handbookId}
          onClose={handleOnCloseModal}
        />
      )}
      {isPreviewModalVisibile && (
        <PreviewModal
          onClose={handleOnClosePreviewModal}
          newsHtml={previewHtml}
        />
      )}
    </div>
  );
}

export default Handbook;
