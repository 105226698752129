import { configureStore } from "@reduxjs/toolkit";
import flightSchoolReducer from "../page/School/schoolSlice";
import authReducer from "../redux/slices/authSlice";

const reducers = {
  auth: authReducer,
  flightSchool: flightSchoolReducer,
};

const store = configureStore({
  reducer: reducers,
});
export default store;
