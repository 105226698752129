import { functions } from "../lib/firebase";
import {httpsCallable} from 'firebase/functions'

export const scheduleNews = (scheduledDate, docId, title, description, urlToImage) => {
    var scheduleNewsFromAdmin = httpsCallable(functions,'scheduleNewsFromAdmin');
    return scheduleNewsFromAdmin({ 
        date: scheduledDate,
        docId: docId,
        title, 
        description,
        urlToImage
     })
}

export const sendNewsNotif = (docId, title, description, urlToImage) => {
    var sendNotifOnPublish = httpsCallable(functions,'sendNewsNotif');
    return sendNotifOnPublish({ 
        docId,
        title, 
        description,
        urlToImage
     })
     .then((res)=>console.log('sendNewsNotif res',res))
     .catch((err)=>console.log('sendNewsNotif Err',err))
}