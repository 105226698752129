import React from "react";

import "./Button.styles.css";

function Button({ children, disabled, onClick, className }) {
  return (
    <button
      onClick={onClick}
      className={`button ${className}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
